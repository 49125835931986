import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromAuth from './auth.reducer';
import * as fromTotal from './total.reducer';
import * as fromBravo from './bravo.reducer';
import * as fromTag from './tag.reducer';
import * as fromAssignment from './assigment.reducer';
import * as fromRouter from './router.reducer';
import {routerReducer, RouterReducerState} from "@ngrx/router-store";
import {BravoAssignments, TagAssignments} from "./assigment.reducer";
import {BravoList} from "./bravo.reducer";
import {Bravo} from "../../app.model";
import {TagList} from "./tag.reducer";

export interface State {
  auth: fromAuth.State;
  total: fromTotal.State;
  bravo: fromBravo.State;
  tag: fromTag.State;
  router: RouterReducerState;
  assignment: fromAssignment.State;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  total: fromTotal.reducer,
  bravo: fromBravo.reducer,
  tag: fromTag.reducer,
  router: routerReducer,
  assignment: fromAssignment.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

// Auth selectors
export const selectAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getUser = createSelector(selectAuthState, fromAuth.getUser);

// Total selectors
export const selectTotalState = createFeatureSelector<fromTotal.State>('total');
export const getTagsTotal = createSelector(selectTotalState, fromTotal.getTagsTotal);
export const getBravoTotal = createSelector(selectTotalState, fromTotal.getBravoTotal);
export const getBravo24Total = createSelector(selectTotalState, fromTotal.getBravo24Total);

// Bravo selectors
export const selectBravoState = createFeatureSelector<fromBravo.State>('bravo');
export const getAllBravos = createSelector(selectBravoState, fromBravo.getAllBravos);
export const getBravos24 = createSelector(selectBravoState, fromBravo.getBravos24);
export const getFilteredBravos = createSelector(selectBravoState, fromBravo.getFilteredBravos);
export const getBravoLoading = createSelector(selectBravoState, fromBravo.getLoading);
export const getBravoFilter = createSelector(selectBravoState, fromBravo.getFilter);
export const getBravoPageIndex = createSelector(selectBravoState, fromBravo.getPageIndex);
export const getBravoPageSize = createSelector(selectBravoState, fromBravo.getPageSize);
export const getBravoSortField = createSelector(selectBravoState, fromBravo.getSortField);
export const getBravoSortDirection = createSelector(selectBravoState, fromBravo.getSortDirection);
export const getBravoByIds = createSelector(
  getAllBravos,
  (state: BravoList, props: {bravoIds: string[]}) => {
    let filtered: Bravo[] = [];
    props.bravoIds.forEach(bravoId => filtered.push(state[bravoId]));
    return filtered;
  }
);

// Tag selectors
export const selectTagState = createFeatureSelector<fromTag.State>('tag');
export const getAllTags = createSelector(selectTagState, fromTag.getAllTags);
export const getTag = createSelector(
  getAllTags,
  (state: TagList, props: {tagId: string}) => {
    return state[props.tagId];
  }
);
export const getFilteredTags = createSelector(selectTagState, fromTag.getFilteredTags);
export const getTagLoading = createSelector(selectTagState, fromTag.getLoading);
export const getTagFilter = createSelector(selectTagState, fromTag.getFilter);
export const getTagPageIndex = createSelector(selectTagState, fromTag.getPageIndex);
export const getTagPageSize = createSelector(selectTagState, fromTag.getPageSize);
export const getTagSortField = createSelector(selectTagState, fromTag.getSortField);
export const getTagSortDirection = createSelector(selectTagState, fromTag.getSortDirection);

// Router selectors
export const selectRouterState = createFeatureSelector<RouterReducerState>('router');
export const getRouterState = createSelector(selectRouterState, fromRouter.getState);
export const getRouterNavigationId = createSelector(selectRouterState, fromRouter.getNavigationId);

// Assignment selectors
export const selectAssignmentsState = createFeatureSelector<fromAssignment.State>('assignment');
export const getTagAssignments = createSelector(selectAssignmentsState, fromAssignment.getTagAssignments);
export const getTagAssignment = createSelector(
  getTagAssignments,
  (state: TagAssignments, props: {tagId: string}) => {
    return state[props.tagId];
  }
);
export const getBravoAssignments = createSelector(selectAssignmentsState, fromAssignment.getBravoAssignments);
export const getBravoAssignment = createSelector(
  getBravoAssignments,
  (state: BravoAssignments, props: {bravoId: string}) => {
    return state[props.bravoId];
  }
);
