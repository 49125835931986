import {createAction, props, union} from '@ngrx/store';
import {User} from "../../app.model";

export enum AuthActionTypes {
  PersistAuth = '[Auth] Persist Auth',
  SetAuth = '[Auth] Set Auth',
  SetAuthSuccess = '[Auth] Set Auth Success',
  ClearAuth = '[Auth] Clear Auth',
  ClearAuthSuccess = '[Auth] Clear Auth Success',
}

export const PersistAuth = createAction(
  AuthActionTypes.PersistAuth,
  props<{user: User}>(),
);

export const SetAuth = createAction(
  AuthActionTypes.SetAuth,
  props<{user: User}>(),
);

export const SetAuthSuccess = createAction(AuthActionTypes.SetAuthSuccess);

export const ClearAuth = createAction(AuthActionTypes.ClearAuth);

export const ClearAuthSuccess = createAction(AuthActionTypes.ClearAuthSuccess);

// export type AuthApiActionsUnion = ReturnType<typeof loginSuccess | typeof loginFailure>;
const all = union({PersistAuth, SetAuth, SetAuthSuccess, ClearAuth, ClearAuthSuccess});
export type AuthActions = typeof all;
