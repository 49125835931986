import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {TagDataSource, TagsService} from "../../shared/services/tags.service";

import {fromEvent, Observable, Subscription} from "rxjs";

import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";

import {MatPaginator, MatProgressBar, MatSnackBar, MatSort, PageEvent, Sort} from "@angular/material";

import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {CommandsService} from "../../shared/services/commands.service";
import {Location} from "@angular/common";
import {Command, User} from "../../app.model";

import {Title} from "@angular/platform-browser";
import {BravoService} from "../../shared/services/bravo.service";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import {
  LoadFilteredTags,
  RemoveTag,
  SetFilter,
  SetPageIndex,
  SetPageSize,
  SetSortDirection
} from "../../store/actions/tag.actions";
import OrderByDirection = firebase.firestore.OrderByDirection;
import * as pluralize from "pluralize";
import {TagAssignments} from "../../store/reducers/assigment.reducer";

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  // styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscription: Subscription = new Subscription();

  color: MatProgressBar["color"] = 'primary';
  mode: MatProgressBar["mode"] = 'query';

  pageSizeOptions: number[] = [3, 5, 10];

  displayedColumns = ['title', 'star'];

  private searchString: string = '';

  total$: Observable<number>;
  pageSize$: Observable<number>;
  loading$: Observable<boolean>;
  user$: Observable<User>;
  filter$: Observable<string>;
  private tagAssignments$: Observable<TagAssignments>;

  constructor(
    private store: Store<fromRoot.State>,
    private snackBar: MatSnackBar,
    private bravoService: BravoService,
    private titleService: Title,
    private commandService: CommandsService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tagsService: TagsService,
    private dataSource: TagDataSource) {

    this.subscription.add(commandService.commands$.subscribe(c => this.handleCommand(c)));

    this.total$ = store.select(fromRoot.getTagsTotal);
    this.pageSize$ = store.select(fromRoot.getTagPageSize);
    this.loading$ = store.select(fromRoot.getTagLoading);
    this.user$ = store.select(fromRoot.getUser);
    this.filter$ = store.select(fromRoot.getTagFilter);

    this.tagAssignments$ = store.select(fromRoot.getTagAssignments);


  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatProgressBar, {static: false}) progressBar: MatProgressBar;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('input', {static: true}) input: ElementRef;

  ngOnInit() {
    // this.course = this.route.snapshot.data["course"];
    // this.dataSource.loadTags();

    this.subscription.add(
      this.pageSize$.subscribe(state => this.paginator.pageSize = state)
    );

    this.subscription.add(
      this.store.select(fromRoot.getTagPageIndex).subscribe(state => this.paginator.pageIndex = state)
    );

    this.subscription.add(
      this.store.select(fromRoot.getTagsTotal)
        .subscribe((total: number) => {
            if (total) {
              this.titleService.setTitle(pluralize('tag', total, true))
            } else {
              this.titleService.setTitle('loading tags...');
            }
          }
        )
    );

    this.subscription.add(
      this.paginator.page
        .pipe(
          tap((evt: PageEvent) => {
            this.store.dispatch(SetPageSize({pageSize: evt.pageSize}));
            this.store.dispatch(SetPageIndex({pageIndex: evt.pageIndex}));
            this.store.dispatch(LoadFilteredTags());

            const url = this.router
              .createUrlTree([{page: evt.pageIndex, size: evt.pageSize}], {relativeTo: this.activatedRoute})
              .toString();
            this.location.go(url);

          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.sort.sortChange.pipe(
        tap((evt: Sort) => {
          // reset the paginator after sorting
          this.store.dispatch(SetPageIndex({pageIndex: 0}));
          this.store.dispatch(SetSortDirection({sortDirection: evt.direction as OrderByDirection}));
          this.store.dispatch(LoadFilteredTags());

        })
      ).subscribe()
    );

    let paramMap: ParamMap = this.activatedRoute.snapshot.paramMap;
    if (paramMap.keys.length > 0) {
      this.updatePaging(paramMap);
    }

    this.user$.subscribe(user => {
      if (user) {
        this.store.dispatch(LoadFilteredTags());
      }
    });

  }

  ngAfterViewInit() {

    this.subscription.add(
      // server-side search
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            this.store.dispatch(SetPageIndex({pageIndex: 0}));
            this.store.dispatch(SetFilter({filter: this.searchString}));
            this.store.dispatch(LoadFilteredTags())          })
        )
        .subscribe()
    );

  }

  updatePaging(paramMap: ParamMap) {
    console.log('params');
    const pageIndex: number = Number(paramMap.get('page'));
    const pageSize: number = Number(paramMap.get('size'));
    if (pageSize) {
      console.log(`set pageSize=${paramMap.get('size')}`);

      this.store.dispatch(SetPageSize({pageSize: pageSize}));
    }
    if (pageIndex) {
      console.log(`set pageIndex=${paramMap.get('page')}`);
      this.store.dispatch(SetPageIndex({pageIndex: pageIndex}));
    }
  }

  deleteTag(tagId: string): void {
    this.store.dispatch(RemoveTag({tagId: tagId}));
    this.store.dispatch(LoadFilteredTags());
  }

  clearSearch() {
    // this.input.nativeElement.value = '';
    this.searchString = '';
    this.store.dispatch(SetFilter({filter: ''}));
    this.store.dispatch(LoadFilteredTags())
  }

  private handleCommand(c: Command) {
    switch (c.name) {
      case 'AppComponent.Search': {
        this.input.nativeElement.focus();
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  pluralize(text: string, number: number) {
    return pluralize(text, number, true);
  }
}

