import {Bravo} from "../../app.model";
import OrderByDirection = firebase.firestore.OrderByDirection;
import {createReducer, on} from "@ngrx/store";
import {
  SetAllBravos, SetBravos24,
  SetFilter, SetFilteredBravos,
  SetPageIndex,
  SetPageSize,
  SetSortDirection,
  SetSortField
} from "../actions/bravo.actions";
import {SetLoading} from "../actions/tag.actions";
import keyBy from 'lodash/keyBy';

export interface BravoList extends Map<string, Bravo[]>{}

export interface State {
  allBravos: BravoList;
  bravos24: Bravo[];
  filteredBravos: Bravo[];
  loading: boolean;
  pageSize: number;
  pageIndex: number;
  sortField: string;
  sortDirection: OrderByDirection;
  filter: string;
}

export const initialState: State = {
  allBravos: new Map(),
  bravos24: [],
  filteredBravos: [],
  loading: false,
  pageSize: 3,
  pageIndex: 0,
  sortField: 'title',
  sortDirection: 'asc',
  filter: '',
};

export const reducer = createReducer(
  initialState,
  on(SetFilter, (state, {filter}) => ({
      ...state,
      filter: filter,
    })
  ),
  on(SetSortField, (state, {sortField}) => ({
      ...state,
      sortField: sortField,
    })
  ),
  on(SetSortDirection, (state, {sortDirection}) => ({
      ...state,
      sortDirection: sortDirection,
    })
  ),
  on(SetPageSize, (state, {pageSize}) => ({
      ...state,
      pageSize: pageSize,
    })
  ),
  on(SetPageIndex, (state, {pageIndex}) => ({
      ...state,
      pageIndex: pageIndex,
    })
  ),
  on(SetLoading, (state, {loading}) => ({
      ...state,
      loading: loading,
    })
  ),
  on(SetAllBravos, (state, {allBravos}) => ({
      ...state,
      allBravos: keyBy(allBravos, b => b.id)
    })
  ),
  on(SetBravos24, (state, {bravos24}) => ({
      ...state,
      bravos24: bravos24,
    })
  ),
  on(SetFilteredBravos, (state, {filteredBravos}) => ({
      ...state,
      filteredBravos: filteredBravos,
    })
  ),
);

export const getLoading = (state: State) => state.loading;
export const getAllBravos = (state: State) => state.allBravos;
export const getBravos24 = (state: State) => state.bravos24;
export const getFilteredBravos = (state: State) => state.filteredBravos;
export const getPageSize = (state: State) => state.pageSize;
export const getPageIndex = (state: State) => state.pageIndex;
export const getSortField = (state: State) => state.sortField;
export const getSortDirection = (state: State) => state.sortDirection;
export const getFilter = (state: State) => state.filter;
