import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Bravo, Tag} from "../../app.model";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {Title} from "@angular/platform-browser";
import {map, tap} from "rxjs/operators";
import {MatSnackBar} from "@angular/material";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import {RemoveTag} from "../../store/actions/tag.actions";
import {getBravoByIds} from "../../store/reducers";
import * as pluralize from "pluralize";

@Component({
  selector: 'app-tag-detail',
  templateUrl: './tag-detail.component.html',
  // styleUrls: ['./tag-detail.component.scss']
})
export class TagDetailComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  tag$: Observable<Tag>;
  bravosTotal$: Observable<number>;

  private readonly tagId: string;

  bravosSubject = new BehaviorSubject<Bravo[]>([]);
  public bravos$ = this.bravosSubject.asObservable();

  constructor(
    private store: Store<fromRoot.State>,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private titleService: Title,
  ) {
    this.tagId = this.route.snapshot.paramMap.get('id');
    this.tag$ = this.store.select(fromRoot.getTag, {tagId: this.tagId});
  }

  ngOnInit() {
    // this.store.dispatch(LoadTag({tagId: this.tagId}));
    this.bravosTotal$ = this.store.select(fromRoot.getTagAssignment, {tagId: this.tagId})
      .pipe(
        tap(bravoIds => {
          if (bravoIds) {
            console.log(`total: ${bravoIds.length}`);
            this.store.select(getBravoByIds, {bravoIds: bravoIds})
              .pipe(
                tap(value => {
                  if (value) {console.log(`value: ${value.length}`)}
                })
              )
              .subscribe((value: Bravo[]) => this.bravosSubject.next(value))
          }
        }),
        map (bravoIds => {
          if (bravoIds) {
            return bravoIds.length;
          } else {
            return 0;
          }
        }),
      );
    this.subscription.add(
      this.tag$.subscribe(
        x => {
          this.titleService.setTitle(`Tag: ${x.title}`);
        }
      )
    );
  }

  deleteTag(tagId: string) {
    this.store.dispatch(RemoveTag({tagId: tagId}));
    this.ngZone.run(() => this.router.navigate(['/tags']));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  pluralize(text: string, number: number) {
    return pluralize(text, number, true);
  }

}
