export class Bravo {

  id: string;
  title: string;
  color: string;
  description: string;
  owner: string; //uid

  createdAt: number; // UNIX epoch timestamp
  lastModifiedAt: number; // UNIX epoch timestamp
}

export class Tag {

  id: string;
  title: string;
  color: string;
  description: string;
  owner: string;

  createdAt: number; // UNIX epoch timestamp
  lastModifiedAt: number; // UNIX epoch timestamp

}

export class Assignment {
  id: string;
  owner: string;
  bravoId: string;
  tagId: string;
}

export class User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

export class PageRecord {
  totalEntities: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: number[];
}

export class HotkeyDetails {
  description: string;
  commands: string[];
}

export class HotkeyConfig {
  [key: string]: HotkeyDetails;
}

export class ConfigModel {
  hotkeys: HotkeyConfig;
}

export class Command {
  name: string;
  combo: string;
  ev: KeyboardEvent;
}
