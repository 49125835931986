import {Pipe, PipeTransform} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map, switchMap} from "rxjs/operators";

@Pipe({name: 'image'})
export class ImagePipe implements PipeTransform {

  constructor(private http: HttpClient) {}

  transform(url: string) {

    return this.getImage(url).pipe(
      switchMap(response => {
        return new Observable<string|ArrayBuffer>(observer => {
          const reader = new FileReader();
          reader.readAsDataURL(response); // convert blob to base64
          reader.onloadend = function () {
            observer.next(reader.result); // emit the base64 string result
          }
        });
      })
    );

  }

  private getImage(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob', // specify that response should be treated as blob data
      headers: ({
        // 'Access-Control-Allow-Origin': '*'
      })
    });
  }

}
