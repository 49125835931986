import {MatSnackBarConfig} from "@angular/material";

export const config = {
  tags_endpoint: "tags",
  users_endpoint: "users",
  bravo_endpoint: "bravo",
  assignment_endpoint: "assignment",
};

export const snackBarConfig: MatSnackBarConfig = {
  duration: 2000,
  horizontalPosition: "right",
  verticalPosition: "bottom",
};
