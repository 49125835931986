import {Component, NgZone, OnInit} from '@angular/core';
import {TagsService} from "../../shared/services/tags.service";
import {Tag} from "../../app.model";
import {Subject} from "rxjs";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-tag-edit',
  templateUrl: './tag-edit.component.html',
  // styleUrls: ['./tag-edit.component.css']
})
export class TagEditComponent implements OnInit {

  private tag$: Subject<Tag> = new Subject();
  private readonly tagId: string;

  constructor(
    private ngZone: NgZone,
    private titleService: Title,
    private tagService: TagsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.tagId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.loadTag();
  }

  private loadTag() {
    this.tagService.getTag(this.tagId).subscribe(
      x => {
        this.tag$.next(x);
        this.titleService.setTitle(`Edit tag: ${x.title}`);
      }
    );
  }

  private tagUpdated(tag: Tag): void {
    this.tagService.updateTag(tag.id, tag);
    this.ngZone.run(() => this.router.navigate([`/tag/${this.tagId}`]))
  }

}
