import {createReducer, on} from "@ngrx/store";
import {SetBravo24Total, SetBravoTotal, SetTagsTotal} from "../actions/total.actions";

export interface State {
  tagsTotal?: number;
  bravoTotal?: number;
  bravo24Total?: number;
}

export const initialState: State = {
  tagsTotal: 0,
  bravoTotal: 0,
  bravo24Total: 0,
};

export const reducer = createReducer(
  initialState,
  on(SetTagsTotal, (state, {tagsTotal}) => ({
      ...state,
      tagsTotal: tagsTotal,
    })
  ),
  on(SetBravoTotal, (state, {bravoTotal}) => ({
      ...state,
      bravoTotal: bravoTotal,
    })
  ),
  on(SetBravo24Total, (state, {bravo24Total}) => ({
      ...state,
      bravo24Total: bravo24Total,
    })
  ),
);

export const getTagsTotal = (state: State) => state.tagsTotal;
export const getBravoTotal = (state: State) => state.bravoTotal;
export const getBravo24Total = (state: State) => state.bravo24Total;
