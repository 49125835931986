import {Injectable, OnDestroy} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import {FormControl, FormGroup} from "@angular/forms";
import {
  Tag,
  User,
} from "../../app.model";
import {config} from "../../app.config"
import {map, mergeMap, tap, withLatestFrom} from "rxjs/operators";
import OrderByDirection = firebase.firestore.OrderByDirection;
import {
  from,
  Observable,
  Subscription,
} from "rxjs";
import {DataSource} from "@angular/cdk/table";
import {AngularFirePerformance} from "@angular/fire/performance";
import now from 'lodash/now';
import {AssignmentService} from "./assignment.service";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import {LoadTags} from "../../store/actions/tag.actions";
import {PaginateService} from "./paginate.service";
import {CollectionViewer} from "@angular/cdk/collections";

@Injectable({
  providedIn: 'root'
})
export class TagsService implements OnDestroy {

  private subscription: Subscription = new Subscription();

  private tags: AngularFirestoreCollection<Tag>;

  user$: Observable<User>;
  uid: string;
  pageIndex$: Observable<number>;
  pageSize$: Observable<number>;
  sortField$: Observable<string>;
  sortDirection$: Observable<OrderByDirection>;
  filter$: Observable<string>;

  form = new FormGroup({
    owner: new FormControl(''),
    title: new FormControl(''),
    description: new FormControl(''),
    color: new FormControl(''),
  });

  constructor(
    private store: Store<fromRoot.State>,
    private assignmentService: AssignmentService,
    private afp: AngularFirePerformance,
    private db: AngularFirestore) {

    this.user$ = this.store.select(fromRoot.getUser);

    this.subscription.add(
      this.user$.subscribe(user => {
        if (user && user.uid) {
          console.log(`tagService user: ${user.email}/${user.uid}`);
          this.uid = user.uid;
          this.tags = this.db.collection(config.tags_endpoint, ref => ref.where("owner", "==", user.uid));
        }
      })
    );

    this.pageIndex$ = this.store.select(fromRoot.getTagPageIndex);
    this.pageSize$ = this.store.select(fromRoot.getTagPageSize);
    this.sortField$ = this.store.select(fromRoot.getTagSortField);
    this.sortDirection$ = this.store.select(fromRoot.getTagSortDirection);
    this.filter$ = this.store.select(fromRoot.getTagFilter);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createTag(tag: Tag): Observable<Tag> {

    let ts = now();
    let uid = this.uid;
    tag.owner = uid;
    tag.createdAt = ts;
    tag.lastModifiedAt = ts;
    return from(new Promise<any>((resolve, reject) => {
      this.tags
        .add(tag)
        .then(
          res => {
            console.log("createTag: Tag saved for %s", uid);
            this.store.dispatch(LoadTags());
            resolve(tag)
          },
          err => reject(err)
        );
    }));
  }

  updateTag(id: string, update: Partial<Tag>): Promise<void> {

    //Get the task document
    let tagDoc = this.tags.doc<Tag>(id);

    update.lastModifiedAt = now();

    return tagDoc.update(update);

  }

  deleteTag(id: string): Observable<void> {
    //Get the task document
    let tagDoc = this.tags.doc<Tag>(id);

    return from(tagDoc.delete());
  }

  loadFilteredTags(): Observable<Tag[]> {

    return this.user$.pipe(
      withLatestFrom(this.sortField$, this.sortDirection$),
      mergeMap(([user, sortField, sortDirection]) => {
        console.log(`user: ${user.uid}; sortField: ${sortField}; sortDirection: ${sortDirection}`);
        return this.db.collection(
          config.tags_endpoint,
          ref => {
              let query = ref.where("owner", "==", this.uid);
              if (sortField && sortDirection) {
                console.log(`sortField: ${sortField}; sortDirection: ${sortDirection}`);
                query = query.orderBy(sortField, sortDirection);
              }
              return query;
          })
          .get()
          .pipe(
            this.afp.trace('getFilteredTags'),
            withLatestFrom(this.filter$, this.pageIndex$, this.pageSize$),
            map(([querySnapshot, filter, pageIndex, pageSize]) => {

              console.log(`filter: ${filter}; pageIndex: ${pageIndex}; pageSize: ${pageSize}`);

              console.log(`size: ${querySnapshot.docs.length}`);

              let markers: Tag[] = [];
              querySnapshot.docs.forEach(doc => {
                let t = doc.data() as Tag;
                t.id = doc.id;
                if (filter) {
                  //do filtering
                  if (t.title.includes(filter)) {
                    markers.push(t);
                  }
                } else {
                  markers.push(t);
                }
              });

              let pageRecord = PaginateService.paginate(markers.length, pageIndex + 1, pageSize);

              // this.store.dispatch(new SetFilteredTags(markers.slice(pageRecord.startIndex, pageRecord.endIndex + 1)));

              console.log("got %d tags: filter=%s, sortField=%s, sortDirection=%s, pageIndex=%d, pageSize=%d", markers.length, filter, sortField, sortDirection, pageIndex, pageSize);

              // this.store.dispatch(new SetTags(markers));

              return markers.slice(pageRecord.startIndex, pageRecord.endIndex + 1);
            })
          )
      })
    );
  }

  getAllTags(): Observable<Tag[]> {

    return this.db.collection(
      config.tags_endpoint,
      ref => ref.where("owner", "==", this.uid))
      .get()
      .pipe(
        this.afp.trace('getTags'),
        map(querySnapshot => {

          let markers: Tag[] = [];
          querySnapshot.docs.forEach(doc => {
            let t = doc.data() as Tag;
            t.id = doc.id;
            markers.push(t);
          });

          console.log("got %d tags", markers.length);

          return markers;
        })
      );
  }

  getTag(id: string): Observable<Tag> {

    return this.db.doc(`/${config.tags_endpoint}/${id}`)
      .get()
      .pipe(
        this.afp.trace('getTag'),
        map(documentSnapshot => {

          // console.log(`snapshot: ${JSON.stringify(documentSnapshot.data())}`);

          let data = documentSnapshot.data() as Tag;

          data.id = id;

          console.log("got Tag %s: %s", id, JSON.stringify(data));

          return data;
        })
      );
  }

}

@Injectable({providedIn: 'root'})
export class TagDataSource extends DataSource<any> {

  constructor(private store: Store<fromRoot.State>) {
    super();
  }

  connect(viewer: CollectionViewer): Observable<Tag[]> {
    viewer.viewChange.pipe(
      tap(evt => console.log(`viewer event: ${JSON.stringify(evt)}`))
    );
    return this.store.select(fromRoot.getFilteredTags);
  }

  disconnect() {
    //no-op
  }

}
