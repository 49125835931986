import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {User} from "../../app.model";
import {config} from "../../app.config";
import {auth} from 'firebase/app';
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import {ClearAuth, PersistAuth, SetAuth} from "../../store/actions/auth.actions";

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<fromRoot.State>,
    private db: AngularFirestore,   // Inject Firestore service
    private auth: AngularFireAuth, // Inject Firebase auth service
    private router: Router,
    private ngZone: NgZone // NgZone service to remove 'outside scope' warning
  ) {

    this.subscription.add(
      this.auth.authState.subscribe(user => {
        /* Saving user data in localstorage when
        logged in and setting up null when logged out */
        if (user) {
          console.log('authState: %s/%s', user.email, user.uid);
          this.store.dispatch(SetAuth({user: user}));
          localStorage.setItem('user', JSON.stringify(user));  //fixme: implement as effect
        } else {
          this.store.dispatch(ClearAuth());
        }

      })
    );

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getLocalStorageUser(): User {
    let user: User = JSON.parse(localStorage.getItem('user'));
    console.log('localStorage UID: %s', user.uid);
    return user;
  }

// Sign in with email/password
  signIn(email, password): Promise<void> {
    return this.auth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => this.router.navigate(['dashboard']));
        this.store.dispatch(PersistAuth({user: result.user}));
      }).catch((error) => {
        window.alert(error.message)
      })
  }

// Sign up with email/password
  signUp(email, password): Promise<void> {
    return this.auth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.sendVerificationMail();
        this.store.dispatch(PersistAuth({user: result.user}));
      }).catch((error) => {
        window.alert(error.message)
      })
  }

// Send email verfificaiton when new user sign up
  sendVerificationMail(): Promise<boolean | never> {
    return this.auth.auth.currentUser.sendEmailVerification()
      .then(() => this.ngZone.run(() =>
        this.router.navigate(['verify-email-address'])
      ))
  }

// Reset Forgotten password
  forgotPassword(passwordResetEmail): Promise<void> {
    return this.auth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

// Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false);
  }

// Sign in with Google
  googleAuth(): Promise<void> {
    return this.authLogin(new auth.GoogleAuthProvider());
  }

// Sign in with Facebook
  facebookAuth(): Promise<void> {
    return this.authLogin(new auth.FacebookAuthProvider());
  }

// Auth logic to run auth providers
  authLogin(provider): Promise<void> {
    return this.auth.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => this.router.navigate(['dashboard']));
        this.store.dispatch(PersistAuth({user: result.user}));
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  public setUserData(user): Promise<void> {
    console.log('setUserData');
    const userRef: AngularFirestoreDocument<any> = this.db.doc(`/${config.users_endpoint}/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    };
    // console.log('setUserData: uid=%s, email=%s', user.uid, user.email);
    // this.userDataSubject.next(this.userData);
    return userRef.set(userData, {
      merge: true
    })
  }

// Sign out
  signOut(): Promise<void | never> {
    return this.auth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.ngZone.run(fn => this.router.navigate(['sign-in']));
    })
  }

}
