import {
  Injectable,
  OnDestroy
} from '@angular/core';

import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";

import {config} from "../../app.config";
import {DatePipe} from "@angular/common";

import {
  Bravo,
  User,
  Tag,
} from "../../app.model";

import {
  from,
  Observable,
  Subscription,
} from "rxjs";

import {
  map, mergeMap,
  tap, withLatestFrom,
} from "rxjs/operators";

import {AngularFirePerformance} from "@angular/fire/performance";
import now from 'lodash/now';
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import {DataSource} from "@angular/cdk/table";
import OrderByDirection = firebase.firestore.OrderByDirection;
import {FormControl, FormGroup} from "@angular/forms";
import {CollectionViewer} from "@angular/cdk/collections";
import {PaginateService} from "./paginate.service";

@Injectable({
  providedIn: 'root'
})
export class BravoService implements OnDestroy {

  private subscription: Subscription = new Subscription();

  private bravosRef: AngularFirestoreCollection<Bravo>;

  private tagsRef: AngularFirestoreCollection<Tag>;
  public tags$: Observable<Tag[]>;
  public tags: Tag[];

  user$: Observable<User>;
  uid: string;

  pageIndex$: Observable<number>;
  pageSize$: Observable<number>;
  sortField$: Observable<string>;
  sortDirection$: Observable<OrderByDirection>;
  filter$: Observable<string>;

  public form = new FormGroup({
    owner: new FormControl(''),
    color: new FormControl(''),
    description: new FormControl(''),
    title: new FormControl(''),
  });

  constructor(
    private store: Store<fromRoot.State>,
    private afp: AngularFirePerformance,
    private datePipe: DatePipe,
    private db: AngularFirestore) {

    this.user$ = this.store.select(fromRoot.getUser);

    this.pageIndex$ = this.store.select(fromRoot.getBravoPageIndex);
    this.pageSize$ = this.store.select(fromRoot.getBravoPageSize);
    this.sortField$ = this.store.select(fromRoot.getBravoSortField);
    this.sortDirection$ = this.store.select(fromRoot.getBravoSortDirection);
    this.filter$ = this.store.select(fromRoot.getBravoFilter);

    this.subscription.add(
      this.user$.subscribe((user: User) => {

        if (user && user.uid) {
          this.uid = user.uid;
          this.bravosRef = this.db.collection(
            config.bravo_endpoint,
            ref => ref.where("owner", "==", user.uid)
          );

          this.tagsRef = this.db.collection(config.tags_endpoint, ref => ref.where("owner", "==", user.uid));
          this.tags$ = this.tagsRef.snapshotChanges().pipe(
            map(action => {

              let cached = 0;

              let tags: Tag[] = action.map(a => {
                const data = a.payload.doc.data() as Tag;

                let metadata = a.payload.doc.metadata;
                if (metadata.fromCache) {
                  cached += 1;
                }

                const id = a.payload.doc.id;
                return {...data, id: id};
              });

              console.log(`loaded ${tags.length} tags (cached: ${cached})`);

              this.tags = tags;
              return tags;
            }));

        } else {
          console.warn(`skip empty user`);
        }

      })
    );

  }

  createBravo(bravo: Bravo): Observable<Bravo> {

    const ts = now();
    const uid = this.uid;
    bravo.owner = uid;
    bravo.createdAt = ts;
    bravo.lastModifiedAt = ts;

    return from(new Promise<Bravo>((resolve, reject) => {
      this.bravosRef
        .add(bravo)
        .then(
          res => {
            console.log("createBravo: Bravo saved for %s", uid);
            res.get()
              .then(value => resolve({...value.data() as Bravo, id: value.id}))
          },
          err => reject(err)
        );
    }));
  }

  loadFilteredBravos(): Observable<Bravo[]> {

    return this.user$.pipe(
      withLatestFrom(this.sortField$, this.sortDirection$),
      mergeMap(([user, sortField, sortDirection]) => {
        console.log(`user: ${user.uid}; sortField: ${sortField}; sortDirection: ${sortDirection}`);
        return this.db.collection(
          config.bravo_endpoint,
          ref => {
            let query = ref.where("owner", "==", this.uid);
            if (sortField && sortDirection) {
              console.log(`sortField: ${sortField}; sortDirection: ${sortDirection}`);
              query = query.orderBy(sortField, sortDirection);
            }
            return query;
          })
          .get()
          .pipe(
            this.afp.trace('getFilteredBravos'),
            withLatestFrom(this.filter$, this.pageIndex$, this.pageSize$),
            map(([querySnapshot, filter, pageIndex, pageSize]) => {

              console.log(`filter: ${filter}; pageIndex: ${pageIndex}; pageSize: ${pageSize}`);

              console.log(`size: ${querySnapshot.docs.length}`);

              let markers: Bravo[] = [];
              querySnapshot.docs.forEach(doc => {
                let t = doc.data() as Bravo;
                t.id = doc.id;
                if (filter) {
                  //do filtering
                  if (t.title.includes(filter)) {
                    markers.push(t);
                  }
                } else {
                  markers.push(t);
                }
              });

              let pageRecord = PaginateService.paginate(markers.length, pageIndex + 1, pageSize);

              console.log("got %d bravos: filter=%s, sortField=%s, sortDirection=%s, pageIndex=%d, pageSize=%d", markers.length, filter, sortField, sortDirection, pageIndex, pageSize);

              return markers.slice(pageRecord.startIndex, pageRecord.endIndex + 1);
            })
          )
      })
    );
  }

  loadBravos(timestamp?: number): Observable<Bravo[]> {
    return this.db.collection(
      config.bravo_endpoint,
      ref => {
        let query = ref.where("owner", "==", this.uid);
        if (timestamp) {
          query = query.where('createdAt', '>', timestamp)
        }
        query = query.orderBy('createdAt', 'desc');
        return query;
      }
    ).get()
      .pipe(
        this.afp.trace('loadBravos'),
        map(querySnapshot => {
          let markers: Bravo[] = [];
          querySnapshot.docs.forEach(doc => {
            let t = doc.data() as Bravo;
            t.id = doc.id;
            markers.push(t);
          });

          if (timestamp) {
            let date = new Date(timestamp);
            let transformed: string = this.datePipe.transform(date, 'full'); // https://angular.io/api/common/DatePipe
            console.log(`fetched ${markers.length} records for ${this.uid} since: ${transformed}`);
          } else {
            console.log(`fetched ${markers.length} records for ${this.uid}`);
          }

          return markers;

        })
      );
  }

  getBravo(id: string): Observable<Bravo> {

    return this.db.doc(`/${config.bravo_endpoint}/${id}`)
      .get()
      .pipe(
        this.afp.trace('getBravo'),
        map(documentSnapshot => {

          let data = documentSnapshot.data() as Bravo;

          data.id = id;

          return data;
        })
      );
  }

  public getAllRecords(): Observable<Bravo[]> {
    let recordsRef = this.db.collection(
      config.bravo_endpoint,
        ref => ref.where("owner", "==", this.uid).orderBy('createdAt', 'desc')
    );
    return recordsRef.get().pipe(
      this.afp.trace('getAllBravos'),
      map(querySnapshot => {
        let bravos: Bravo[] = [];
        querySnapshot.docs.forEach(doc => {
          let t = doc.data() as Bravo;
          t.id = doc.id;
          bravos.push(t);
        });

        // this.store.dispatch(new SetBravos(bravos));

        return bravos;
      }));
  }

  updateBravo(id: string, update: Partial<Bravo>): Promise<void> {
    //Get the task document
    let recordDoc = this.bravosRef.doc<Bravo>(id);
    update.lastModifiedAt = now();
    return recordDoc.update(update);
  }

  public deleteBravo(bravoId: string) {

    //Get assignment document
    let recordDoc = this.bravosRef.doc<Bravo>(bravoId);

    console.log(`removing bravo record ${bravoId}`);

    //Delete the document
    return from(recordDoc.delete());

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

@Injectable({ providedIn: 'root' })
export class BravoDataSource extends DataSource<Bravo> {

  constructor(
    private store: Store<fromRoot.State>) {
    super();
  }

  connect(viewer: CollectionViewer): Observable<Bravo[]> {
    viewer.viewChange.pipe(
      tap(evt => console.log(`viewer event: ${JSON.stringify(evt)}`))
    );
    return this.store.select(fromRoot.getFilteredBravos);
  }

  disconnect() {
    // no-op
  }

}
