import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Controls, DataInput, NgxRootFormComponent} from "ngx-sub-form";
import {Tag} from "../../../app.model";
import {FormControl} from "@angular/forms";
import {Location} from "@angular/common";

@Component({
  selector: 'app-tag-form',
  templateUrl: './tag-form.component.html',
  // styleUrls: ['./tag-form.component.css']
})
export class TagFormComponent extends NgxRootFormComponent<Tag> {

  @DataInput()
  @Input('tag')
  public dataInput: Tag | null | undefined;

  @Output('tagUpdated')
  public dataOutput: EventEmitter<Tag> = new EventEmitter();

  constructor(private location: Location) {
    super();
  }

  protected getFormControls(): Controls<Tag> {
    return {
      id: new FormControl(),
      title: new FormControl(),
      description: new FormControl(),
      owner: new FormControl(),
      createdAt: new FormControl(),
      lastModifiedAt: new FormControl(),
      color: new FormControl(),
    };
  }

  cancel() {
    this.location.back();
  }
}
