import {Injectable, OnDestroy} from '@angular/core';
import {Hotkey, HotkeysService} from "angular2-hotkeys";
import {Observable, Subject, Subscription} from "rxjs";
import {HttpClient } from '@angular/common/http';
import {Command, ConfigModel} from "../../app.model";

@Injectable({
  providedIn: 'root'
})
export class CommandsService implements OnDestroy { //fixme: migrate to ngrx

  private subscription: Subscription = new Subscription();

  private subject: Subject<Command>;
  commands$: Observable<Command>;

  constructor(private hotkeysService: HotkeysService,
              private http: HttpClient) {
    this.subject = new Subject<Command>();
    this.commands$ = this.subject.asObservable();
    let sub = this.http.get('assets/hotkeys.json').subscribe((c: ConfigModel) => {
      for (const key in c.hotkeys) {
        const commands = c.hotkeys[key];
        hotkeysService.add(
          new Hotkey(
            key,
            (ev, combo) => this.hotkey(ev, combo, commands.commands),
            undefined,
            commands.description
          )
        );
        // console.log("registered hotkey: %s", key)
      }
    });
    this.subscription.add(sub);
  }

  hotkey(ev: KeyboardEvent, combo: string, commands: string[]): boolean {
    commands.forEach(c => {
      const command = {
        name: c,
        ev: ev,
        combo: combo
      } as Command;
      this.subject.next(command);
    });
    return true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
