import {
  map,
  filter,
  mergeMap
} from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from "@angular/router";

import {
  Observable,
  Subscription
} from "rxjs";

import {CommandsService} from "./shared/services/commands.service";
import {AuthService} from "./shared/services/auth.service";
import {Title} from "@angular/platform-browser";
import {MatSidenav} from "@angular/material";
import {Command, User} from "./app.model";
import {Store} from "@ngrx/store";

import * as fromRoot from './store/reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @Output() toggleSidenav = new EventEmitter<void>();

  tagsTotal$: Observable<number>;
  bravoTotal$: Observable<number>;
  bravo24Total$: Observable<number>;

  pageTitle: string = "BravoMe.ORG";

  private subscription: Subscription = new Subscription();

  user$: Observable<User>;
  uid: string;

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  constructor(
    private store: Store<fromRoot.State>,
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService,
    private commandService: CommandsService) {
  }

  ngOnInit(): void {

    this.user$ = this.store.select(fromRoot.getUser);
    this.tagsTotal$ = this.store.select(fromRoot.getTagsTotal);
    this.bravoTotal$ = this.store.select(fromRoot.getBravoTotal);
    this.bravo24Total$ = this.store.select(fromRoot.getBravo24Total);

    this.subscription.add(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      ).subscribe((event) => {
        let eventTitle = event['pageTitle'];
        this.titleService.setTitle(eventTitle);
        this.pageTitle = eventTitle;
      })
    );

    this.subscription.add(
      this.commandService.commands$
        .subscribe(c => this.handleCommand(c))
    );

  }

  handleCommand(command: Command) {
    switch (command.name) {
      case 'AppComponent.OpenDashboard': {
        this.ngZone.run(() => this.router.navigate(['/dashboard']));
        break;
      }
      case 'AppComponent.OpenBravos': {
        this.ngZone.run(() => this.router.navigate(['/bravos']));
        break;
      }
      case 'AppComponent.Sidenav': {
        this.sidenav.toggle();
        break;
      }
      case 'AppComponent.NewBravo': {
        this.ngZone.run(() => this.router.navigate(['/bravo-new']));
        break;
      }
      case 'AppComponent.SignOut': {
        this.authService.signOut();
        break;
      }
      case 'AppComponent.OpenProfile': {
        this.ngZone.run(() => this.router.navigate(['/profile']));
        break;
      }
      case 'AppComponent.OpenTags': {
        this.ngZone.run(() => this.router.navigate(['/tags']));
        break;
      }
      case 'AppComponent.NewTag': {
        this.ngZone.run(() => this.router.navigate(['/tag-new']));
        break;
      }
      case 'AppComponent.OpenWelcome': {
        this.ngZone.run(() => this.router.navigate(['/welcome']));
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
