import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from "rxjs";
import {switchMap} from "rxjs/operators";

import {Action} from "@ngrx/store";
import {AuthService} from "../../shared/services/auth.service";
import {ClearAuth, ClearAuthSuccess, PersistAuth, SetAuth, SetAuthSuccess} from "../actions/auth.actions";
import {LoadBravos, LoadBravos24} from "../actions/bravo.actions";
import {LoadTags} from "../actions/tag.actions";

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
  ) {
  }

  clearAuth$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClearAuth),
      switchMap((action) => {
        console.log('clearAuth$ effect');
        localStorage.removeItem('user');
        return of(ClearAuthSuccess());
      })
    )
  );

  setAuth$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetAuth),
      switchMap((action) => {
        console.log('setAuth$ effect');
        localStorage.setItem('user', JSON.stringify(action.user));
        return of(SetAuthSuccess());
      })
    )
  );

  setAuthSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetAuthSuccess),
      switchMap((action) => {
        console.log('setAuthSuccess$ effect');
        return of(LoadBravos(), LoadTags(), LoadBravos24());
      })
    )
  );

  persistAuth$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PersistAuth),
      switchMap((action, _) => {
        console.log('persistAuth$ effect');
        const user = action.user;
        this.authService.setUserData(user);
        return of(SetAuth({user: user}));
      })
    )
  );

}
