import {Tag} from "../../app.model";
import OrderByDirection = firebase.firestore.OrderByDirection;
import {createReducer, on} from "@ngrx/store";
import {
  SetAllTags,
  SetFilter, SetFilteredTags,
  SetLoading,
  SetPageIndex,
  SetPageSize,
  SetSortDirection,
  SetSortField, SetSingleTag
} from "../actions/tag.actions";
import keyBy from 'lodash/keyBy';

export interface TagList extends Map<string, Tag[]>{}

export interface State {
  tag: Tag,
  allTags: TagList;
  filteredTags: Tag[];
  loading: boolean;
  pageSize: number;
  pageIndex: number;
  sortField: string;
  sortDirection: OrderByDirection;
  filter: string;
}

export const initialState: State = {
  tag: undefined,
  allTags: new Map(),
  filteredTags: [],
  loading: false,
  pageSize: 3,
  pageIndex: 0,
  sortField: 'title',
  sortDirection: 'asc',
  filter: '',
};

export const reducer = createReducer(
  initialState,
  on(SetFilter, (state, {filter}) => ({
      ...state,
      filter: filter,
    })
  ),
  on(SetSingleTag, (state, {tag}) => ({
      ...state,
      tag: tag,
    })
  ),
  on(SetSortField, (state, {sortField}) => ({
      ...state,
    sortField: sortField,
    })
  ),
  on(SetSortDirection, (state, {sortDirection}) => ({
      ...state,
    sortDirection: sortDirection,
    })
  ),
  on(SetPageSize, (state, {pageSize}) => ({
      ...state,
    pageSize: pageSize,
    })
  ),
  on(SetPageIndex, (state, {pageIndex}) => ({
      ...state,
    pageIndex: pageIndex,
    })
  ),
  on(SetLoading, (state, {loading}) => ({
      ...state,
    loading: loading,
    })
  ),
  on(SetAllTags, (state, {allTags}) => ({
      ...state,
    allTags: keyBy(allTags, b => b.id),
    })
  ),
  on(SetFilteredTags, (state, {filteredTags}) => ({
      ...state,
    filteredTags: filteredTags,
    })
  ),
);

export const getLoading = (state: State) => state.loading;
export const getAllTags = (state: State) => state.allTags;
export const getTag = (state: State) => state.tag;
export const getFilteredTags = (state: State) => state.filteredTags;
export const getPageSize = (state: State) => state.pageSize;
export const getPageIndex = (state: State) => state.pageIndex;
export const getSortField = (state: State) => state.sortField;
export const getSortDirection = (state: State) => state.sortDirection;
export const getFilter = (state: State) => state.filter;
