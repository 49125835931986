import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from "rxjs";
import {Action} from "@ngrx/store";
import {map, switchMap, tap} from "rxjs/operators";
import {BravoService} from "../../shared/services/bravo.service";
import {
  CreateBravo, CreateBravoSuccess,
  LoadBravos, LoadBravos24,
  LoadFilteredBravos, RemoveBravo,
  SetAllBravos, SetBravos24,
  SetFilteredBravos,
} from "../actions/bravo.actions";
import {SetBravo24Total, SetBravoTotal} from "../actions/total.actions";
import {snackBarConfig} from "../../app.config";
import {MatSnackBar} from "@angular/material";
import {
  AssignTags,
  ClearBravoAssignments,
  LoadAssignments,
} from "../actions/assignment.actions";

@Injectable()
export class BravoEffects {

  constructor(
    private snackBar: MatSnackBar,
    private actions$: Actions,
    private bravoService: BravoService,
  ) {
  }

  loadBravos$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadBravos),
      switchMap(never => this.bravoService.getAllRecords().pipe(
        map(payload => SetAllBravos({allBravos: payload}))
        )
      )
    )
  );

  loadFilteredBravos$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFilteredBravos),
      switchMap((action) => this.bravoService.loadFilteredBravos().pipe(
        map(payload => SetFilteredBravos({filteredBravos: payload}))
        )
      )
    )
  );

  loadBravos24$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadBravos24),
      switchMap((action) => {
        let date = new Date();
        date.setDate(date.getDate() - 1);
        return this.bravoService.loadBravos(date.getTime())
            .pipe(
              map(payload => SetBravos24({bravos24: payload}))
            )
        }
      )
    )
  );

  setBravos24$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetBravos24),
      switchMap((action) => of(SetBravo24Total({bravo24Total: action.bravos24.length})))
    )
  );

  setBravos$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetAllBravos),
      switchMap((action) => of(
        SetBravoTotal({bravoTotal: action.allBravos.length}),
        LoadAssignments()
      ))
    )
  );

  createBravo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateBravo),
      switchMap((action) => {
        return this.bravoService.createBravo(action.bravo)
          .pipe(
            switchMap(created => of(
              CreateBravoSuccess({bravo: created}),
              AssignTags({bravoId: created.id, tagIds: action.selectedTags})
            ))
          )
      })
    )
  );

  removeBravo$: Observable<Action> = createEffect( () =>
    this.actions$.pipe(
      ofType(RemoveBravo),
      switchMap((action) => {
        return this.bravoService.deleteBravo(action.bravoId)
          .pipe(
            tap(never => {
              let snackRef = this.snackBar.open(`Bravo deleted.`, 'OK', snackBarConfig);
            }),
            switchMap(never => of(
              ClearBravoAssignments({bravoId: action.bravoId}),
              LoadBravos(),
              LoadBravos24(),
            ))
          )
      })
    )
  );

  createBravoSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateBravoSuccess),
      switchMap((action) => {
        let snackRef = this.snackBar.open(`Bravo '${action.bravo.title}' created.`, 'OK', snackBarConfig);
        return of(LoadBravos(), LoadBravos24());
      })
    ),
    // {dispatch: false}
  );

}
