import {NgModule} from '@angular/core';

import {
  MatSidenavModule,
  MatToolbarModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  MatButtonModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatInputModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatStepperModule,
  MatChipsModule,
  MatTooltipModule,
  MatBadgeModule,
  MatDialogModule,
  MatMenuModule,
  MatExpansionModule,
} from '@angular/material';

import {
  MatTableModule,
} from '@angular/material/table'

import {
  FormsModule,
  ReactiveFormsModule
} from "@angular/forms";

@NgModule({
  declarations: [],
  imports: [
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatTooltipModule,
    MatBadgeModule,
    MatDialogModule,
    MatMenuModule,
    MatExpansionModule,
  ],
  exports: [
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatTooltipModule,
    MatBadgeModule,
    MatDialogModule,
    MatMenuModule,
    MatExpansionModule,
  ]
})
export class MaterialModule {
}
