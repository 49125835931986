import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';

import {AngularFireModule} from "@angular/fire";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {SignInComponent} from './components/sign-in/sign-in.component';
import {SignUpComponent} from './components/sign-up/sign-up.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {VerifyEmailComponent} from './components/verify-email/verify-email.component';

import {AppRoutingModule} from './shared/routing/app-routing.module';

import {HotkeyModule} from 'angular2-hotkeys';
import {HttpClientModule} from '@angular/common/http';

import {AuthService} from "./shared/services/auth.service";
import {GoogleAnalyticsService} from "./shared/services/google-analytics.service";
import {BravoNewComponent} from './components/bravo-new/bravo-new.component';
import {ReactiveFormsModule} from "@angular/forms";
import {BravoListComponent} from './components/bravo-list/bravo-list.component';
import {TagDataSource, TagsService} from "./shared/services/tags.service";
import {IHotkeyOptions} from "angular2-hotkeys/src/hotkey.options";
import {MaterialModule} from "./material.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FlexLayoutModule} from "@angular/flex-layout";
import {WelcomeComponent} from './components/welcome/welcome.component';
import {ProfileComponent} from './components/profile/profile.component';
import {TagNewComponent} from "./components/tag-new/tag-new.component";
import {PaginateService} from "./shared/services/paginate.service";
import {TagListComponent} from "./components/tag-list/tag-list.component";
import {TagDetailComponent} from './components/tag-detail/tag-detail.component';
import {BravoDetailComponent} from './components/bravo-detail/bravo-detail.component';
import {LetDirective} from './shared/directives/let.directive';
import {AngularFirePerformance} from "@angular/fire/performance";
import {BravoDialogComponent} from './components/bravo-dialog/bravo-dialog.component';
import {BravoService} from "./shared/services/bravo.service";
import {AssignmentService} from "./shared/services/assignment.service";
import {TimeAgoPipe} from "time-ago-pipe";
import {StoreModule} from '@ngrx/store';
import {reducers, metaReducers} from './store/reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {AppEffects} from './store/effects/app.effects';
import {AuthEffects} from './store/effects/auth.effects';
import {GiphyService} from "./shared/services/giphy.service";
import {TagFormComponent} from './components/tag-detail/tag-form/tag-form.component';
import {CommonModule, DatePipe} from "@angular/common";
import {ImagePipeModule} from "./shared/pipes/image/image-pipe.module";
import {ImagePipe} from "./shared/pipes/image/image.pipe";
import {TagEditComponent} from './components/tag-edit/tag-edit.component';
import {BravoFormComponent} from './components/bravo-detail/bravo-form/bravo-form.component';
import {BravoEditComponent} from './components/bravo-edit/bravo-edit.component';
import {TagEffects} from "./store/effects/tag.effects";
import {BravoEffects} from "./store/effects/bravo.effects";
import {StoreRouterConnectingModule} from "@ngrx/router-store";
import {AssignmentEffects} from "./store/effects/assignment.effects";

var hotkeyOptions: IHotkeyOptions = {
  cheatSheetCloseEsc: true,
  cheatSheetCloseEscDescription: 'Close Hotkeys List',
  cheatSheetDescription: 'Open Hotkeys List'
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    BravoNewComponent,
    BravoListComponent,
    BravoDetailComponent,
    TagNewComponent,
    TagListComponent,
    TagDetailComponent,
    WelcomeComponent,
    ProfileComponent,
    LetDirective,
    BravoDialogComponent,
    TimeAgoPipe,
    TagFormComponent,
    TagEditComponent,
    BravoFormComponent,
    BravoEditComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,    // Only required for auth features
    AngularFirestoreModule.enablePersistence(),   // Only required for database features
    ReactiveFormsModule,
    HotkeyModule.forRoot(hotkeyOptions),
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([AppEffects, AssignmentEffects, AuthEffects, BravoEffects, TagEffects]),
    StoreRouterConnectingModule.forRoot({stateKey:'router'}),
    ImagePipeModule,
  ],
  providers: [
    AuthService,
    GoogleAnalyticsService,
    AngularFirePerformance,
    TagsService,
    AssignmentService,
    PaginateService,
    TagDataSource,
    BravoService,
    DatePipe,
    GiphyService,
    ImagePipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [BravoDialogComponent]
})
export class AppModule {
}
