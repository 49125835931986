import {User} from "../../app.model";
import {createReducer, on} from "@ngrx/store";
import {ClearAuth, PersistAuth, SetAuth} from "../actions/auth.actions";

export interface State {
  user?: User;
}

export const initialState: State = {
  user: undefined,
};

export const reducer = createReducer(
  initialState,
  on(SetAuth, (state, {user}) => ({
      ...state,
      user: user,
    })
  ),
  on(PersistAuth, (state, {user}) => ({
      ...state,
      user: user,
    })
  ),
  on(ClearAuth, (state) => ({
      ...state,
      user: undefined,
    })
  ),
);

export const getUser = (state: State) => state.user;
