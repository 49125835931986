import {Injectable, NgZone} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable, of} from "rxjs";
import {Action} from "@ngrx/store";
import {map, switchMap, tap} from "rxjs/operators";
import {
  AssignTag,
  AssignTags,
  AssignTagsSuccess,
  LoadAssignments,
  RemoveTagAssignment,
  ClearTagAssignments,
  SetAssignments, ClearBravoAssignments,
} from "../actions/assignment.actions";
import {AssignmentService} from "../../shared/services/assignment.service";
import {Router} from "@angular/router";
import * as pluralize from "pluralize";
import {snackBarConfig} from "../../app.config";
import {MatSnackBar} from "@angular/material";
import {GoogleAnalyticsService} from "../../shared/services/google-analytics.service";

@Injectable()
export class AssignmentEffects {

  constructor(
    private analytics: GoogleAnalyticsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private ngZone: NgZone,
    private actions$: Actions,
    private assignmentService: AssignmentService) {
  }

  assignTag$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignTag),
      switchMap((action) =>
        this.assignmentService.assignTag(action.bravoId, action.tagId)
          .pipe(
            tap(assignmentId => {
              console.log(`Tag ${action.tagId} assigned for Bravo ${action.bravoId} in assignment ${assignmentId}`);

              let snackRef = this.snackBar.open(`Tag ${action.tagId} assigned to Bravo ${action.bravoId}`, 'OK', snackBarConfig);

              this.analytics.emitEvent('tag', 'assign', 'click');

            }),
            switchMap(never => of(null))
          )
      )
    ),
    {dispatch: false}
  );

  assignTags$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignTags),
      switchMap((action) =>
        this.assignmentService.assignTags(action.bravoId, action.tagIds)
          .pipe(
            tap(ids => {
              console.log(`${ids.length} Tag ${pluralize('assignment', ids.length)} saved for Bravo ${action.bravoId}`);
            }),
            map(never => AssignTagsSuccess())
          )
      )
    )
  );

  loadAssignments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadAssignments),
      switchMap((action) =>
        this.assignmentService.getAllAssignments()
          .pipe(
            tap(data => console.log(`${pluralize('assignment', data.length, true)} loaded`)),
            map(data => SetAssignments({assignments: data}))
          )
      )
    )
  );

  clearBravoAssignments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClearBravoAssignments),
      switchMap((action) =>
        this.assignmentService.deleteBravo(action.bravoId)
          .pipe(
            tap(total => {
              console.log(`${total} Bravo ${pluralize('assignment', total)} removed for Bravo ${action.bravoId}`);
            }),
            switchMap(never => of(null))
          )
      )
    ),
    {dispatch: false}
  );

  clearTagAssignments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClearTagAssignments),
      switchMap((action) =>
        this.assignmentService.removeTagAssignments(action.tagId)
          .pipe(
            tap(total => {
              console.log(`${total} Tag ${pluralize('assignment', total)} removed for Tag ${action.tagId}`);
            }),
            switchMap(never => of(null))
          )
      )
    ),
    {dispatch: false}
  );

  removeTagAssignment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveTagAssignment),
      switchMap((action) =>
        this.assignmentService.removeTagAssignment(action.bravoId, action.tagId)
          .pipe(
            tap(never => {
              console.log(`Tag assignment ${action.tagId} removed from Bravo ${action.bravoId} `);

              let snackRef = this.snackBar.open(`Tag ${action.tagId} removed from Bravo ${action.bravoId}`, 'OK', snackBarConfig);

              this.analytics.emitEvent('tag', 'remove', 'click');

            }),
            switchMap(never => of(null))
          )
      )
    ),
    {dispatch: false}
  );
}
