import {Component, NgZone, OnInit} from '@angular/core';
import {TagsService} from "../../shared/services/tags.service";
import {Tag} from "../../app.model";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Location} from "@angular/common";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import {CreateTag} from "../../store/actions/tag.actions";

@Component({
  selector: 'app-tag-new',
  templateUrl: './tag-new.component.html',
  // styleUrls: ['./tag-new.component.scss']
})
export class TagNewComponent implements OnInit {
  isLinear: boolean = false;
  titleFormGroup: FormGroup;
  descriptionFormGroup: FormGroup;
  colorFormGroup: FormGroup;

  constructor(
    private store: Store<fromRoot.State>,
    private location: Location,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private tagsService: TagsService,
    private router: Router) {}

  ngOnInit() {
    this.titleFormGroup = this.formBuilder.group({
      title: ['', Validators.required],
    });
    this.descriptionFormGroup = this.formBuilder.group({
      description: ['', Validators.nullValidator],
    });
    this.colorFormGroup = this.formBuilder.group({
      color: ['', Validators.nullValidator],
    });
  }

  onSubmit() {
    let data: Tag = this.tagsService.form.value;
    this.store.dispatch(CreateTag({tag: data}));
    this.ngZone.run(() => this.router.navigate(['/tags']));
  }

  cancel() {
    this.location.back();
  }
}
