import {createAction, props, union} from '@ngrx/store';
import OrderByDirection = firebase.firestore.OrderByDirection;
import {Tag} from "../../app.model";

export enum TagActionTypes {
  CreateTag = '[Tag] Create Tag',
  LoadSingleTag = '[Tag] Load Single Tag',
  SetSingleTag = '[Tag] Set Single Tag',
  CreateTagSuccess = '[Tag] Create Tag Success',
  RemoveTag = '[Tag] Remove Tag',
  LoadTags = '[Tag] Load Tags',
  LoadFilteredTags = '[Tag] Load Filtered Tags',
  SetAllTags = '[Tag] Set All Tags',
  SetFilteredTags = '[Tag] Set Filtered Tags',
  SetLoading = '[Tag] Set Loading',
  SetFilter = '[Tag] Set Filter Text',
  SetSortField = '[Tag] Set Sort field',
  SetSortDirection = '[Tag] Set Sort Direction',
  SetPageSize = '[Tag] Set Page Size',
  SetPageIndex = '[Tag] Set Page Index',
}

export const CreateTag = createAction(
  TagActionTypes.CreateTag,
  props<{ tag: Tag }>(),
);

export const LoadSingleTag = createAction(
  TagActionTypes.LoadSingleTag,
  props<{ tagId: string }>(),
);

export const SetSingleTag = createAction(
  TagActionTypes.SetSingleTag,
  props<{ tag: Tag }>(),
);

export const CreateTagSuccess = createAction(
  TagActionTypes.CreateTagSuccess,
  props<{ tag: Tag }>(),
);

export const RemoveTag = createAction(
  TagActionTypes.RemoveTag,
  props<{tagId: string}>(),
);

export const LoadTags = createAction(TagActionTypes.LoadTags);

export const LoadFilteredTags = createAction(TagActionTypes.LoadFilteredTags);

export const SetAllTags = createAction(
  TagActionTypes.SetAllTags,
  props<{ allTags: Tag[] }>(),
);

export const SetFilteredTags = createAction(
  TagActionTypes.SetFilteredTags,
  props<{ filteredTags: Tag[] }>(),
);

export const SetLoading = createAction(
  TagActionTypes.SetLoading,
  props<{ loading: boolean }>(),
);

export const SetFilter = createAction(
  TagActionTypes.SetFilter,
  props<{ filter: string }>()
);

export const SetSortField = createAction(
  TagActionTypes.SetSortField,
  props<{ sortField: string }>()
);

export const SetSortDirection = createAction(
  TagActionTypes.SetSortDirection,
  props<{ sortDirection: OrderByDirection }>(),
);

export const SetPageSize = createAction(
  TagActionTypes.SetPageSize,
  props<{ pageSize: number }>()
);

export const SetPageIndex = createAction(
  TagActionTypes.SetPageIndex,
  props<{ pageIndex: number }>()
);

const all = union({
  CreateTag,
  LoadSingleTag,
  SetSingleTag,
  CreateTagSuccess,
  RemoveTag,
  LoadTags,
  LoadFilteredTags,
  SetAllTags,
  SetFilteredTags,
  SetLoading,
  SetFilter,
  SetSortField,
  SetSortDirection,
  SetPageSize,
  SetPageIndex
});
export type TagActions = typeof all;
