import {createReducer, on} from "@ngrx/store";
import {
  SetAssignments,
  AssignTag,
  AssignTags,
  RemoveTagAssignment,
  ClearTagAssignments,
  ClearBravoAssignments, RemoveTagAssignments,
} from "../actions/assignment.actions";
import difference from 'lodash/difference';

export interface BravoAssignments {
  [bravoId: string]: string[]
}

export interface TagAssignments {
  [tagId: string]: string[]
}

export interface State {
  /**
   * tags for given bravoId
   */
  bravo: BravoAssignments;

  /**
   * bravos for given tagId
   */
  tag: TagAssignments;
}

export const initialState: State = {
  bravo: {},
  tag: {},
};

function mergeArrays(first: string[], second: string[]) {
  let result = [];
  if (first) {
    result = result.concat(first);
  }
  if (second) {
    result = result.concat(second);
  }
  return result;
}

function assignBravos(state: TagAssignments, tagId: string, bravoIds: string[]) {
  state[tagId] = mergeArrays(bravoIds, state[tagId])
}

function removeBravoAssignment(state: TagAssignments, tagId: string, bravoIds: string[]) {
  state[tagId] = difference(state[tagId], bravoIds);
}

function clearBravoAssignments(state: BravoAssignments, bravoId: string) {
  delete state[bravoId];
}

function assignTags(state: BravoAssignments, bravoId: string, tagIds: string[]) {
  state[bravoId] = mergeArrays(tagIds, state[bravoId])
}

function removeTagAssignment(state: BravoAssignments, bravoId: string, tagIds: string[]) {
  state[bravoId] = difference(state[bravoId], tagIds);
}

function clearTagAssignments(state: TagAssignments, tagId: string) {
  delete state[tagId];
}

export const reducer = createReducer(
  initialState,
  on(SetAssignments, (state, {assignments}) => {
      if (assignments.length > 0) {
        const newState = {
          ...state
        };
        assignments.forEach(a => {
          assignBravos(newState.tag, a.tagId, [a.bravoId]);
          assignTags(newState.bravo, a.bravoId, [a.tagId]);
        });
        return newState;
      } else {
        return state;
      }
    }
  ),
  on(AssignTag, (state, {bravoId, tagId}) => {
      const newState = {
        ...state
      };
      assignBravos(newState.tag, tagId, [bravoId]);
      assignTags(newState.bravo, bravoId, [tagId]);
      return newState;
    }
  ),
  on(AssignTags, (state, {bravoId, tagIds}) => {
      if (tagIds.length > 0) {
        const newState = {
          ...state
        };
        assignTags(newState.bravo, bravoId, tagIds);
        tagIds.forEach(tagId => assignBravos(newState.tag, tagId, [bravoId]));
        return newState;
      } else {
        return state;
      }
    }
  ),
  on(RemoveTagAssignment, (state, {bravoId, tagId}) => {
      const newState = {
        ...state
      };
      removeTagAssignment(newState.bravo, bravoId, [tagId]);
      removeBravoAssignment(newState.tag, tagId, [bravoId]);
      return newState;
    }
  ),
  on(RemoveTagAssignments, (state, {bravoId, tagIds}) => {
      const newState = {
        ...state
      };
      removeTagAssignment(newState.bravo, bravoId, tagIds);
      tagIds.forEach(tagId => removeBravoAssignment(newState.tag, tagId, [bravoId]));
      return newState;
    }
  ),
  on(ClearTagAssignments, (state, {tagId}) => {
      const newState = {
        ...state
      };
      clearTagAssignments(newState.tag, tagId);
      return newState;
    }
  ),
  on(ClearBravoAssignments, (state, {bravoId}) => {
      const newState = {
        ...state
      };
      clearBravoAssignments(newState.bravo, bravoId);
      return newState;
    }
  ),
);

export const getTagAssignments = (state: State) => state.tag;
export const getBravoAssignments = (state: State) => state.bravo;
