import {Component, ElementRef, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export class DialogData {
  payload: string;
  status: string;
}

@Component({
  selector: 'app-bravo-dialog',
  templateUrl: './bravo-dialog.component.html',
  // styleUrls: ['./bravo-dialog.component.scss']
})
export class BravoDialogComponent implements OnInit {

  private panelOpenState: boolean = true;

  @ViewChild('message', { static: false }) message: ElementRef;

  constructor(
    private ngZone: NgZone,
    private thisDialogRef: MatDialogRef<BravoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
  ) { }

  ngOnInit() {
  }

  close() {
    this.thisDialogRef.close();
  }

  private openPanel($event: any) {
    this.panelOpenState = true;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => this.message.nativeElement.focus(), 250);
    });
  }

  private closePanel($event: any) {
    this.panelOpenState = false
  }

  clearMessage() {
    this.data.payload = '';
  }
}
