import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Controls, DataInput, NgxRootFormComponent} from "ngx-sub-form";
import {Bravo} from "../../../app.model";
import {FormControl} from "@angular/forms";
import {Location} from "@angular/common";

@Component({
  selector: 'app-bravo-form',
  templateUrl: './bravo-form.component.html',
})
export class BravoFormComponent extends NgxRootFormComponent<Bravo> {

  @DataInput()
  @Input('bravo')
  public dataInput: Bravo | null | undefined;

  @Output('bravoUpdated')
  public dataOutput: EventEmitter<Bravo> = new EventEmitter();

  constructor(private location: Location) {
    super();
  }

  protected getFormControls(): Controls<Bravo> {
    return {
      id: new FormControl(),
      title: new FormControl(),
      description: new FormControl(),
      owner: new FormControl(),
      createdAt: new FormControl(),
      lastModifiedAt: new FormControl(),
      color: new FormControl(),
    };
  }

  cancel() {
    this.location.back();
  }
}
