import {createAction, props, union} from "@ngrx/store";
import {Assignment} from "../../app.model";

export enum AssignmentActionTypes {
  LoadAssignments = '[Assignment] Load Assignments',
  SetAssignments = '[Assignment] Set Assignments',
  AssignTag = '[Assignment] Assign Tag',
  AssignTags = '[Assignment] Assign Tags',
  AssignTagsSuccess = '[Assignment] Assign Tags Success',
  RemoveTagAssignment = '[Assignment] Remove Tag Assignment',
  RemoveTagAssignments = '[Assignment] Remove Tag Assignments',
  ClearBravoAssignments = '[Assignment] Clear Bravo Assignments',
  ClearTagAssignments = '[Assignment] Clear Tag Assignments',
}

export const LoadAssignments = createAction(AssignmentActionTypes.LoadAssignments);

export const SetAssignments = createAction(
  AssignmentActionTypes.SetAssignments,
  props<{ assignments: Assignment[] }>()
);

export const AssignTag = createAction(
  AssignmentActionTypes.AssignTag,
  props<{ bravoId: string, tagId: string }>()
);

export const AssignTags = createAction(
  AssignmentActionTypes.AssignTags,
  props<{ bravoId: string, tagIds: string[] }>()
);

export const AssignTagsSuccess = createAction(AssignmentActionTypes.AssignTagsSuccess);

export const RemoveTagAssignment = createAction(
  AssignmentActionTypes.RemoveTagAssignment,
  props<{ bravoId: string, tagId: string }>()
);

export const RemoveTagAssignments = createAction(
  AssignmentActionTypes.RemoveTagAssignments,
  props<{ bravoId: string, tagIds: string[] }>()
);

export const ClearTagAssignments = createAction(
  AssignmentActionTypes.ClearTagAssignments,
  props<{ tagId: string }>()
);

export const ClearBravoAssignments = createAction(
  AssignmentActionTypes.ClearBravoAssignments,
  props<{ bravoId: string }>()
);

const all = union({
  LoadAssignments,
  SetAssignments,
  AssignTag,
  AssignTags,
  AssignTagsSuccess,
  RemoveTagAssignment,
  RemoveTagAssignments,
  ClearTagAssignments,
  ClearBravoAssignments,
});
export type AssignmentActions = typeof all;
