import {Injectable, OnDestroy} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/firestore";
import {Assignment, User} from "../../app.model";
import {forkJoin, from, Observable, Subscription} from "rxjs";
import {AngularFirePerformance} from "@angular/fire/performance";
import {config} from "../../app.config";
import {map, tap} from "rxjs/operators";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";

@Injectable({
  providedIn: 'root'
})
export class AssignmentService implements OnDestroy {

  private subscription: Subscription = new Subscription();

  private assignmentsRef: AngularFirestoreCollection<Assignment>;
  public assignments$: Observable<Assignment[]>;

  user$: Observable<User>;
  uid: string;

  constructor(
    private store: Store<fromRoot.State>,
    private afp: AngularFirePerformance,
    private db: AngularFirestore) {

    this.user$ = this.store.select(fromRoot.getUser);

    this.subscription.add(
      this.user$.subscribe(user => {
        if (user && user.uid) {

          console.log(`assignmentService user: ${user.email}/${user.uid}`);
          this.uid = user.uid;

          this.assignmentsRef = this.db.collection(config.assignment_endpoint, ref => ref.where("owner", "==", user.uid));
          this.assignments$ = this.assignmentsRef.snapshotChanges().pipe(
            map(action => {
              let cached = 0;
              let assignments: Assignment[] = action.map(a => {
                const data = a.payload.doc.data() as Assignment;

                let metadata = a.payload.doc.metadata;
                if (metadata.fromCache) {
                  cached += 1;
                }

                const id = a.payload.doc.id;
                return {id, ...data};
              });

              console.log(`loaded ${assignments.length} assignments (cached: ${cached})`);

              return assignments;
            }));

        } else {
          console.warn(`skip empty user`);
        }

      })
    );
  }

  public getAllAssignments(): Observable<Assignment[]> {
    let assignmentsRef = this.db.collection(config.assignment_endpoint, ref => ref.where("owner", "==", this.uid));
    return assignmentsRef.get().pipe(
      this.afp.trace('getAllAssignments'),
      map(querySnapshot => {
        let assignments: Assignment[] = [];
        querySnapshot.docs.forEach(doc => {
          let t = doc.data() as Assignment;
          t.id = doc.id;
          assignments.push(t);
        });
        return assignments;
      }));
  }

  getAssignmentsByBravoId(bravoId: string): Observable<Assignment[]> {
    return this.getAllAssignments().pipe(
      map(assignments => assignments.filter(a => a.bravoId == bravoId)),
    )
  }

  getAssignmentsByTagId(tagId: string): Observable<Assignment[]> {
    return this.getAllAssignments().pipe(
      map(assignments => assignments.filter(a => a.tagId == tagId)),
    )
  }

  public assignTags(bravoId: string, tagIds: string[]): Observable<string[]> {
    let results: Observable<string>[] = [];
    tagIds.forEach(tagId => {
      results.push(this.assignTag(bravoId, tagId))
    });
    return forkJoin(results);
  }

  public assignTag(bravoId: string, tagId: string): Observable<string> {

    let assignments: AngularFirestoreCollection<Assignment> = this.db.collection<Assignment>(
      config.assignment_endpoint,
      ref => ref.where("owner", "==", this.uid)
    );

    let assignment: Assignment = {
      id: '',
      owner: this.uid,
      bravoId: bravoId,
      tagId: tagId,
    };

    return from(assignments.add(assignment))
      .pipe(
        map(value => value.id)
      );
  }

  public removeTagAssignment(bravoId: string, tagId: string): Observable<Assignment[]> {

    let assignments: AngularFirestoreCollection<Assignment> = this.db.collection<Assignment>(
      config.assignment_endpoint,
      ref => ref.where("owner", "==", this.uid)
        .where("bravoId", "==", bravoId)
        .where("tagId", "==", tagId)
    );

    return assignments.get().pipe(
      tap(x => {
        x.docs.forEach(document => this.removeAssignment(document.id));
      }),
      map(querySnapshot => {

        let markers: Assignment[] = [];
        querySnapshot.docs.forEach(doc => {
          let t = doc.data() as Assignment;
          t.id = doc.id;
          markers.push(t);
        });

        return markers;
      })
    );

  }

  public deleteBravo(bravoId: string): Observable<number> {
    return from(new Promise<number>((resolve) => {
      this.getAssignmentsByBravoId(bravoId).subscribe(assignments => {
        assignments.forEach(a => this.removeAssignment(a.id));
        resolve(assignments.length);
      })
    }))
  }

  public removeTagAssignments(tagId: string): Observable<number> {
    return from(new Promise<number>((resolve) => {
      this.getAssignmentsByTagId(tagId).subscribe(assignments => {
        assignments.forEach(a => this.removeAssignment(a.id));
        resolve(assignments.length);
      })
    }))
  }

  private removeAssignment(assignmentId: string): Observable<void> {

    //Get assignment document
    let assignmentDoc = this.assignmentsRef.doc<Assignment>(assignmentId);

    console.log(`removing assignment ${assignmentId}`);

    //Delete the document
    return from(assignmentDoc.delete());

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
