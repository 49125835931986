import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from "rxjs";
import {Action} from "@ngrx/store";
import {map, switchMap, tap} from "rxjs/operators";
import {TagsService} from "../../shared/services/tags.service";
import {
  CreateTag,
  CreateTagSuccess,
  LoadFilteredTags,
  LoadSingleTag,
  LoadTags, RemoveTag,
  SetAllTags,
  SetFilteredTags,
  SetSingleTag,
} from "../actions/tag.actions";
import {SetTagsTotal} from "../actions/total.actions";
import {snackBarConfig} from "../../app.config";
import {MatSnackBar} from "@angular/material";
import {ClearTagAssignments} from "../actions/assignment.actions";

@Injectable()
export class TagEffects {

  constructor(
    private snackBar: MatSnackBar,
    private actions$: Actions,
    private tagService: TagsService,
  ) {
  }

  loadTags$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadTags),
      switchMap(never => this.tagService.getAllTags()
        .pipe(
          map(tags => SetAllTags({allTags: tags}))
        )
      )
    )
  );

  loadSingleTag$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSingleTag),
      switchMap(action => this.tagService.getTag(action.tagId)
        .pipe(
          map(tag => SetSingleTag({tag: tag}))
        )
      )
    )
  );

  loadFilteredTags$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFilteredTags),
      switchMap(never => this.tagService.loadFilteredTags().pipe(
        map(tags => SetFilteredTags({filteredTags: tags}))
        )
      )
    )
  );

  setAllTags$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetAllTags),
      switchMap((value) => of(SetTagsTotal({tagsTotal: value.allTags.length})))
    )
  );

  createTag$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateTag),
      switchMap((action) => {
        return this.tagService.createTag(action.tag)
          .pipe(
            switchMap(created => of(
              CreateTagSuccess({tag: created}),
              LoadTags(),
            ))
          )
      })
    )
  );

  removeTag$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveTag),
      switchMap((action) => {
        return this.tagService.deleteTag(action.tagId)
          .pipe(
            tap(never => {
              let snackRef = this.snackBar.open(`Tag deleted.`, 'OK', snackBarConfig);
            }),
            switchMap(never => of(
              ClearTagAssignments({tagId: action.tagId}),
              LoadTags(),
            ))
          )
      })
    )
  );

  createTagSuccess$: Observable<Action> = createEffect(() =>
      this.actions$.pipe(
        ofType(CreateTagSuccess),
        switchMap((action) => {
          let snackRef = this.snackBar.open(`Tag '${action.tag.title}' created`, 'OK', snackBarConfig);
          return of(null);
        })
      ),
    {dispatch: false}
  );


}
