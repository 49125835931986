import {createAction, props, union} from '@ngrx/store';
import {Bravo} from "../../app.model";
import OrderByDirection = firebase.firestore.OrderByDirection;

export enum BravoActionTypes {
  CreateBravo = '[Bravo] Create Bravo',
  CreateBravoSuccess = '[Bravo] Create Bravo Success',
  RemoveBravo = '[Bravo] Remove Bravo',
  LoadBravos = '[Bravo] Load Bravos',
  SetAllBravos = '[Bravo] Set All Bravos',
  LoadBravos24 = '[Bravo] Load Bravos 24',
  SetBravos24 = '[Bravo] Set Bravos 24',
  LoadFilteredBravos = '[Bravo] Load Filtered Bravos',
  SetFilteredBravos = '[Bravo] Set Filtered Bravos',
  SetLoading = '[Bravo] Set Loading',
  SetFilter = '[Bravo] Set Filter Text',
  SetSortField = '[Bravo] Set Sort field',
  SetSortDirection = '[Bravo] Set Sort Direction',
  SetPageSize = '[Bravo] Set Page Size',
  SetPageIndex = '[Bravo] Set Page Index',
}

export const CreateBravo = createAction(
  BravoActionTypes.CreateBravo,
  props<{bravo: Bravo, selectedTags: string[]}>(),
);

export const CreateBravoSuccess = createAction(
  BravoActionTypes.CreateBravoSuccess,
  props<{bravo: Bravo}>(),
);

export const CreateBravoError = createAction(
  BravoActionTypes.CreateBravoSuccess,
  props<{error: any}>(),
);

export const RemoveBravo = createAction(
  BravoActionTypes.RemoveBravo,
  props<{bravoId: string}>(),
);

export const LoadBravos = createAction(BravoActionTypes.LoadBravos);

export const SetAllBravos = createAction(
  BravoActionTypes.SetAllBravos,
  props<{allBravos: Bravo[]}>(),
);

export const LoadBravos24 = createAction(BravoActionTypes.LoadBravos24);

export const SetBravos24 = createAction(
  BravoActionTypes.SetBravos24,
  props<{bravos24: Bravo[]}>(),
);

export const LoadFilteredBravos = createAction(
  BravoActionTypes.LoadFilteredBravos,
  props<{timestamp?: number}>(),
);

export const SetFilteredBravos = createAction(
  BravoActionTypes.SetFilteredBravos,
  props<{filteredBravos: Bravo[]}>(),
);

export const SetLoading = createAction(
  BravoActionTypes.SetLoading,
  props<{loading: boolean}>(),
);

export const SetFilter = createAction(
  BravoActionTypes.SetFilter,
  props<{filter: string}>(),
);

export const SetSortField = createAction(
  BravoActionTypes.SetSortField,
  props<{sortField: string}>(),
);

export const SetSortDirection = createAction(
  BravoActionTypes.SetSortDirection,
  props<{sortDirection: OrderByDirection}>(),
);

export const SetPageSize = createAction(
  BravoActionTypes.SetPageSize,
  props<{pageSize: number}>(),
);

export const SetPageIndex = createAction(
  BravoActionTypes.SetPageIndex,
  props<{pageIndex: number}>(),
);

const all = union({
  LoadBravos,
  LoadBravos24,
  LoadFilteredBravos,
  SetAllBravos,
  SetFilteredBravos,
  SetLoading,
  SetFilter,
  SetSortField,
  SetSortDirection,
  SetPageSize,
  SetPageIndex,
  CreateBravo,
  CreateBravoSuccess,
  CreateBravoError,
});
export type BravoActions = typeof all;
