import {Component, NgZone, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {Bravo} from "../../app.model";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {BravoService} from "../../shared/services/bravo.service";

@Component({
  selector: 'app-bravo-edit',
  templateUrl: './bravo-edit.component.html',
})
export class BravoEditComponent implements OnInit {

  private bravo$: Subject<Bravo> = new Subject();
  private readonly bravoId: string;

  constructor(
    private ngZone: NgZone,
    private titleService: Title,
    private bravoService: BravoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.bravoId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.loadBravo();
  }

  private loadBravo() {
    this.bravoService.getBravo(this.bravoId).subscribe(
      x => {
        this.bravo$.next(x);
        this.titleService.setTitle(`Edit bravo: ${x.title}`);
      }
    );
  }

  private bravoUpdated(bravo: Bravo): void {
    this.bravoService.updateBravo(bravo.id, bravo);
    this.ngZone.run(() => this.router.navigate([`/bravo/${this.bravoId}`]))
  }

}
