import {Component} from '@angular/core';
import * as fromRoot from "../../store/reducers";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {User} from "../../app.model";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  // styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  user$: Observable<User>;

  constructor(
    private store: Store<fromRoot.State>) {
    this.user$ = this.store.select(fromRoot.getUser);
  }

}
