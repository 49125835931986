import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fromEvent, Subscription, Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import OrderByDirection = firebase.firestore.OrderByDirection;
import {MatPaginator, MatProgressBar, MatSort, PageEvent, Sort} from "@angular/material";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Location} from "@angular/common";
import {CommandsService} from "../../shared/services/commands.service";

import {Command, Tag, User} from "../../app.model";

import {Title} from "@angular/platform-browser";
import {BravoDataSource, BravoService} from "../../shared/services/bravo.service";
import * as fromRoot from "../../store/reducers";
import {Store} from "@ngrx/store";
import {
  LoadFilteredBravos, RemoveBravo, SetFilter,
  SetPageIndex,
  SetPageSize,
  SetSortDirection
} from "../../store/actions/bravo.actions";
import * as pluralize from "pluralize";
import {BravoAssignments} from "../../store/reducers/assigment.reducer";
import {AssignmentService} from "../../shared/services/assignment.service";
import {TagList} from "../../store/reducers/tag.reducer";

@Component({
  selector: 'app-bravo-list',
  templateUrl: './bravo-list.component.html',
})
export class BravoListComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscription: Subscription = new Subscription();

  color: MatProgressBar["color"] = 'primary';
  mode: MatProgressBar["mode"] = 'query';

  pageSizeOptions: number[] = [3, 5, 10];

  displayedColumns = ['title', 'tags', 'star'];

  private searchString: string = '';

  total$: Observable<number>;
  pageSize$: Observable<number>;
  loading$: Observable<boolean>;
  user$: Observable<User>;
  private bravoAssignments$: Observable<BravoAssignments>;
  private allTags$: Observable<TagList>;
  private tags: TagList = undefined;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatProgressBar, {static: false}) progressBar: MatProgressBar;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('input', {static: true}) input: ElementRef;

  constructor(
    private store: Store<fromRoot.State>,
    private bravoService: BravoService,
    private titleService: Title,
    private commandService: CommandsService,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataSource: BravoDataSource,
    private assignmentService: AssignmentService,
  ) {

    this.subscription.add(commandService.commands$.subscribe(c => this.handleCommand(c)));

    this.total$ = store.select(fromRoot.getBravoTotal);
    this.pageSize$ = store.select(fromRoot.getBravoPageSize);
    this.loading$ = store.select(fromRoot.getBravoLoading);
    this.user$ = store.select(fromRoot.getUser);
    this.bravoAssignments$ = store.select(fromRoot.getBravoAssignments);
    this.allTags$ = store.select(fromRoot.getAllTags);

  }

  ngOnInit() {
    // this.course = this.route.snapshot.data["course"];

    this.subscription.add(
      this.pageSize$.subscribe(state => this.paginator.pageSize = state)
    );

    this.subscription.add(
      this.store.select(fromRoot.getBravoPageIndex).subscribe(state => this.paginator.pageIndex = state)
    );

    this.subscription.add(
      this.store.select(fromRoot.getBravoTotal)
        .subscribe((total: number) => {
            if (total) {
              this.titleService.setTitle(pluralize('bravo', total, true))
            } else {
              this.titleService.setTitle('loading bravos...');
            }
          }
        )
    );

    this.subscription.add(
      this.paginator.page
        .pipe(
          tap((evt: PageEvent) => {
            this.store.dispatch(SetPageSize({pageSize: evt.pageSize}));
            this.store.dispatch(SetPageIndex({pageIndex: evt.pageIndex}));

            this.store.dispatch(LoadFilteredBravos({}));

            const url = this.router
              .createUrlTree([{page: evt.pageIndex, size: evt.pageSize}], {relativeTo: this.activatedRoute})
              .toString();
            this.location.go(url);

          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.sort.sortChange.pipe(
        tap((evt: Sort) => {
          // reset the paginator after sorting
          this.store.dispatch(SetPageIndex({pageIndex: 0}));
          this.store.dispatch(SetSortDirection({sortDirection: evt.direction as OrderByDirection}));
          this.store.dispatch(LoadFilteredBravos({}));
        })
      ).subscribe()
    );

    let paramMap: ParamMap = this.activatedRoute.snapshot.paramMap;
    if (paramMap.keys.length > 0) {
      this.updatePaging(paramMap);
    }

    this.user$.subscribe(user => {
      if (user) {
        this.store.dispatch(LoadFilteredBravos({}));
      }
    });

    this.allTags$.subscribe(tags => {
      if (tags) {
        console.log(`All Tags: ${JSON.stringify(tags)}`);
        this.tags = tags;
      }
    });

  }

  ngAfterViewInit() {

    this.subscription.add(
      // server-side search
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            this.store.dispatch(SetPageIndex({pageIndex: 0}));
            this.store.dispatch(SetFilter({filter: this.searchString}));
            this.store.dispatch(LoadFilteredBravos({}));
          })
        )
        .subscribe()
    );

  }

  updatePaging(paramMap: ParamMap) {
    console.log('params');
    const pageIndex: number = Number(paramMap.get('page'));
    const pageSize: number = Number(paramMap.get('size'));
    if (pageSize) {
      console.log(`set pageSize=${paramMap.get('size')}`);
      this.store.dispatch(SetPageSize({pageSize: pageSize}));
    }
    if (pageIndex) {
      console.log(`set pageIndex=${paramMap.get('page')}`);
      this.store.dispatch(SetPageIndex({pageIndex: pageIndex}));
    }
  }

  deleteBravo(bravoId: string): void {
    this.store.dispatch(RemoveBravo({bravoId: bravoId}));
    this.store.dispatch(LoadFilteredBravos({}));
  }

  clearSearch() {
    // this.input.nativeElement.value = '';
    this.searchString = '';
    this.store.dispatch(SetFilter({filter: ''}));
    this.store.dispatch(LoadFilteredBravos({}));
  }

  private handleCommand(command: Command) {
    switch (command.name) {
      case 'AppComponent.Search': {
        this.input.nativeElement.focus();
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  pluralize(text: string, number: number) {
    return pluralize(text, number, true);
  }

  private getBravoTags(bravoId: string, tags: Tag[]): Observable<Tag[]> {
    return this.assignmentService.getAssignmentsByBravoId(bravoId).pipe(
      map(assignments => assignments.map(a => tags.find(tag => tag.id == a.tagId)))
    )
  }

  stringify(data: any) {
    return JSON.stringify(data);
  }
}
