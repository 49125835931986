import {createAction, props, union} from '@ngrx/store';

export enum TotalActionTypes {
  SetTagsTotal = '[Total] Set Tags Total',
  SetBravoTotal = '[Total] Set Bravo Total',
  SetBravo24Total = '[Total] Set Bravo 24 Total',
}

export const SetTagsTotal = createAction(
  TotalActionTypes.SetTagsTotal,
  props<{tagsTotal: number;}>(),
);

export const SetBravoTotal = createAction(
  TotalActionTypes.SetBravoTotal,
  props<{bravoTotal: number;}>(),
);

export const SetBravo24Total = createAction(
  TotalActionTypes.SetBravo24Total,
  props<{bravo24Total: number;}>(),
);

const all = union({SetTagsTotal, SetBravoTotal, SetBravo24Total});
export type TotalActions = typeof all;
