import {Component, OnDestroy, OnInit} from '@angular/core';
import {Bravo, Tag} from "../../app.model";
import {AngularFirestore} from "@angular/fire/firestore";
import {MatSnackBar} from "@angular/material";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AssignmentService} from "../../shared/services/assignment.service";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {Location} from "@angular/common";
import {BravoService} from "../../shared/services/bravo.service";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import {CreateBravo} from "../../store/actions/bravo.actions";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-bravo-new',
  templateUrl: './bravo-new.component.html',
})
export class BravoNewComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  isLinear: boolean = false;
  titleFormGroup: FormGroup;
  descriptionFormGroup: FormGroup;
  colorFormGroup: FormGroup;
  tagsFormGroup: FormGroup;
  selectedTags: Tag[] = [];

  tagsSubject: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);
  public tags$: Observable<Tag[]> = this.tagsSubject.asObservable();

  urlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  url$: Observable<string> = this.urlSubject.asObservable();

  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private location: Location,
    private assignmentService: AssignmentService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private db: AngularFirestore,
    private bravoService: BravoService) {

    this.subscription.add(
      this.bravoService.tags$.pipe(
        tap(tags => this.tagsSubject.next(tags))
      ).subscribe()
    );

  }

  static getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  ngOnInit() {

    let sub = this.http.get('assets/bravo.json')
      .subscribe((data: string[]) => {
        const idx = BravoNewComponent.getRandomInt(0, data.length);
        this.urlSubject.next(data[idx]);
      });

    this.subscription.add(sub);

    this.titleFormGroup = this.formBuilder.group({
      title: ['', Validators.required],
    });
    this.descriptionFormGroup = this.formBuilder.group({
      description: ['', Validators.nullValidator],
    });
    this.colorFormGroup = this.formBuilder.group({
      color: ['', Validators.nullValidator],
    });
    this.tagsFormGroup = this.formBuilder.group({
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addTag(tag: Tag) {
    this.selectedTags.push(tag);
  }

  removeTag(tag: Tag) {
    let index = this.selectedTags.indexOf(tag);
    if (index > -1) this.selectedTags.splice(index, 1);
  };

  onSubmit(): void {
    let data: Bravo = this.bravoService.form.value;
    this.store.dispatch(CreateBravo({bravo: data, selectedTags: this.selectedTags.map(t => t.id)}));
  }

  cancel() {
    this.location.back();
  }
}
