import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Bravo, Tag} from "../../app.model";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {Title} from "@angular/platform-browser";
import {map} from "rxjs/operators";
import {AssignmentService} from "../../shared/services/assignment.service";
import differenceWith from 'lodash/differenceWith';
import {GoogleAnalyticsService} from "../../shared/services/google-analytics.service";
import {BravoService} from "../../shared/services/bravo.service";
import {TagsService} from "../../shared/services/tags.service";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import {RemoveBravo} from "../../store/actions/bravo.actions";
import {AssignTag, RemoveTagAssignment} from "../../store/actions/assignment.actions";

@Component({
  selector: 'app-bravo-detail',
  templateUrl: './bravo-detail.component.html',
  // styleUrls: ['./bravo-detail.component.scss']
})
export class BravoDetailComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  private bravoSubject = new BehaviorSubject<Bravo>(undefined);
  private bravo$: Observable<Bravo> = this.bravoSubject.asObservable();

  private readonly bravoId: string;

  availableTagsSubject = new BehaviorSubject<Tag[]>([]);
  public availableTags$ = this.availableTagsSubject.asObservable();

  assignedTagsSubject = new BehaviorSubject<Tag[]>([]);
  public assignedTags$ = this.assignedTagsSubject.asObservable();

  constructor(
    private store: Store<fromRoot.State>,
    private ngZone: NgZone,
    private router: Router,
    private analytics: GoogleAnalyticsService,
    private titleService: Title,
    private route: ActivatedRoute,
    private bravoService: BravoService,
    private tagService: TagsService,
    private assignmentService: AssignmentService,
  ) {
    this.bravoId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.loadBravo();
    this.loadTags();
  }

  private loadTags() { //fixme: substitute with reducer
    this.tagService.getAllTags().subscribe(allTags => {

      this.getBravoTags(this.bravoId, allTags).subscribe(assignedTags => {
        let delta = differenceWith(allTags, assignedTags, (a: Tag, b: Tag) => a.title == b.title);
        this.availableTagsSubject.next(delta);
        this.assignedTagsSubject.next(assignedTags)
      });

    });
  }

  private loadBravo() {
    this.bravoService.getBravo(this.bravoId).subscribe(
      x => {
        this.titleService.setTitle(`Bravo: ${x.title}`);
        this.bravoSubject.next(x);
      }
    );
  }

  private getBravoTags(bravoId: string, tags: Tag[]): Observable<Tag[]> {
    return this.assignmentService.getAssignmentsByBravoId(bravoId).pipe(
      map(assignments => assignments.map(a => tags.find(tag => tag.id == a.tagId)))
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  assignTag(bravoId: string, tagId: string) {
    this.store.dispatch(AssignTag({tagId, bravoId}));
    this.loadTags();
  }

  removeTag(bravoId: string, tagId: string) {
    this.store.dispatch(RemoveTagAssignment({tagId, bravoId}));
    this.loadTags();
  }

  deleteBravo(bravoId: string): void {
    this.store.dispatch(RemoveBravo({bravoId: bravoId}));
    this.ngZone.run(() => this.router.navigate(['/bravos']));
  }

}
