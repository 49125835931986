import {NgModule} from '@angular/core';

// Required services for navigation
import {Routes, RouterModule} from '@angular/router';

// Import all the components for which navigation service has to be activated
import {SignInComponent} from '../../components/sign-in/sign-in.component';
import {SignUpComponent} from '../../components/sign-up/sign-up.component';
import {DashboardComponent} from '../../components/dashboard/dashboard.component';
import {ForgotPasswordComponent} from '../../components/forgot-password/forgot-password.component';

// Import canActivate guard services
import {AuthGuard} from "../guard/auth.guard";
import {SecureInnerPagesGuard} from "../guard/secure-inner-pages.guard";
import {VerifyEmailComponent} from '../../components/verify-email/verify-email.component';
import {WelcomeComponent} from "../../components/welcome/welcome.component";
import {ProfileComponent} from "../../components/profile/profile.component";
import {TagListComponent} from "../../components/tag-list/tag-list.component";
import {TagNewComponent} from "../../components/tag-new/tag-new.component";
import {BravoDetailComponent} from "../../components/bravo-detail/bravo-detail.component";
import {TagDetailComponent} from "../../components/tag-detail/tag-detail.component";
import {TagEditComponent} from "../../components/tag-edit/tag-edit.component";
import {BravoNewComponent} from '../../components/bravo-new/bravo-new.component';
import {BravoEditComponent} from "../../components/bravo-edit/bravo-edit.component";
import {BravoListComponent} from "../../components/bravo-list/bravo-list.component";

const routes: Routes = [
  {path: '', redirectTo: '/sign-in', pathMatch: 'full'},
  {path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard], data: {title: 'sign in'}},
  {path: 'register-user', component: SignUpComponent, canActivate: [SecureInnerPagesGuard], data: {title: 'register'}},
  {path: 'welcome', component: WelcomeComponent, data: {title: 'welcome'}},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], data: {title: 'profile'}},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: {title: 'dashboard'}},
  {path: 'bravos', component: BravoListComponent, canActivate: [AuthGuard], data: {title: 'bravos'}},
  {path: 'bravo/:id', component: BravoDetailComponent, canActivate: [AuthGuard], data: {title: 'bravo details'}},
  {path: 'bravo/:id/edit', component: BravoEditComponent, canActivate: [AuthGuard], data: {title: 'edit bravo'}},
  {path: 'bravo-new', component: BravoNewComponent, canActivate: [AuthGuard], data: {title: 'new bravo'}},
  {path: 'tags', component: TagListComponent, canActivate: [AuthGuard], data: {title: 'tags'}},
  {path: 'tag/:id', component: TagDetailComponent, canActivate: [AuthGuard], data: {title: 'tag details'}},
  {path: 'tag/:id/edit', component: TagEditComponent, canActivate: [AuthGuard], data: {title: 'edit tag'}},
  {path: 'tag-new', component: TagNewComponent, canActivate: [AuthGuard], data: {title: 'new tag'}},
  {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard], data: {title: 'forgot password'}},
  {path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard], data: {title: 'verify e-mail'}},
  {path: '**', redirectTo: '/dashboard', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
