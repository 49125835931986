import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

declare var ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService implements OnDestroy{

  private subscription: Subscription = new Subscription();

  constructor(public router: Router) {

    this.subscription.add(
      this.router.events
        .subscribe(event => {
          if (event instanceof NavigationEnd) {
            this.sendPage(event.urlAfterRedirects);
          }
        })
    )

  }

  sendPage(urlAfterRedirects: string): void {
    try {
      if (typeof ga === 'function') {
        ga('set', 'page', urlAfterRedirects);
        ga('send', 'pageview', {
          hitCallback: function () {
            console.log("page: " + urlAfterRedirects)
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Emit google analytics event
   * Fire event example:
   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
   * @param {string} eventCategory
   * @param {string} eventAction
   * @param {string} eventLabel
   * @param {number} eventValue
   */
  public emitEvent(eventCategory: string, eventLabel: string, eventAction: string = 'click', eventValue: number = null): void {

    try {
      if (typeof ga === 'function') {
        ga('send', 'event', {
          eventCategory: eventCategory,
          eventLabel: eventLabel,
          eventAction: eventAction,
          eventValue: eventValue,
          hitCallback: function () {
            console.log("evt: category=%s, label=%s, action=%s", eventCategory, eventLabel, eventAction)
          }
        });

      }
    } catch (e) {
      console.log(e);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
