import {Component, OnDestroy, OnInit} from '@angular/core';
import {BravoDialogComponent, DialogData} from "../bravo-dialog/bravo-dialog.component";
import {MatDialog, MatSnackBar} from "@angular/material";
import {Observable, Subscription} from "rxjs";
import {BravoService} from "../../shared/services/bravo.service";
import {DatePipe} from "@angular/common";
import {Bravo, User} from "../../app.model";
import {snackBarConfig} from "../../app.config";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import {LoadBravos24, RemoveBravo} from "../../store/actions/bravo.actions";
import {tap} from "rxjs/operators";
import {Title} from "@angular/platform-browser";
import * as pluralize from "pluralize";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  // styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  bravos$: Observable<Bravo[]>;
  bravos24$: Observable<Bravo[]>;
  bravosTotal$: Observable<number>;
  user$: Observable<User>;

  private subscription: Subscription = new Subscription();

  constructor(
    private titleService: Title,
    private store: Store<fromRoot.State>,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private bravoService: BravoService,
    public dialog: MatDialog,
  ) {
    this.bravos$ = this.store.select(fromRoot.getFilteredBravos);
    this.bravos24$ = this.store.select(fromRoot.getBravos24);
    this.bravosTotal$ = this.store.select(fromRoot.getBravoTotal);
    this.user$ = this.store.select(fromRoot.getUser);
  }

  ngOnInit() {

    this.subscription.add(
      this.user$.subscribe(user => {
        if (user) {
          this.store.dispatch(LoadBravos24());
        }
      })
    );

    this.subscription.add(
      this.store.select(fromRoot.getBravo24Total)
        .subscribe((total: number) => {
            if (total) {
              this.titleService.setTitle(pluralize('bravo', total, true) + ' in 24h')
            } else {
              this.titleService.setTitle('waiting for bravos ...');
            }
          }
        )
    );

  }

  openDialog() {
    const data: DialogData = {
      payload: '',
      status: '',
    };
    let dialogRef = this.dialog.open(BravoDialogComponent, {
      width: '600px',
      data: data,
    });
    dialogRef.afterClosed().subscribe((data: DialogData) => {
      console.log(`Data from dialog: ${JSON.stringify(data)}`);
      if (data && data.status == 'save') {
        let bravo: Bravo = new Bravo();
        bravo.title = data.payload;
        this.bravoService.createBravo(bravo).pipe(
          tap(record => {
              console.log(`Record created: ${JSON.stringify(record)}`);

              let date = new Date(record.createdAt);
              let transformed: string = this.datePipe.transform(date, 'longTime'); // https://angular.io/api/common/DatePipe

              let snackRef = this.snackBar.open(`Record saved at ${transformed}`, 'OK', snackBarConfig);

            },
            reason => {
              console.log(`error: ${reason}`);
            })
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  deleteRecord(bravoId: string): void {
    this.store.dispatch(RemoveBravo({bravoId: bravoId}));
    this.store.dispatch(LoadBravos24());
  }
}
