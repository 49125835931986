import {Component} from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import {Observable} from "rxjs";
import {User} from "../../app.model";

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  // styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent {

  user$: Observable<User>;

  constructor(
    private store: Store<fromRoot.State>,
    public authService: AuthService,
  ) {
    this.user$ = this.store.select(fromRoot.getUser);
  }


}
